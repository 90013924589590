import {
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  Table,
  TableOptions,
  TableState,
  useReactTable,
} from '@tanstack/react-table';
import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { HorizontalScroll } from '@va/ui/design-system';
import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SegmentCellV8, SegmentNameCellV8 } from './cells/SegmentCellV8';
import './data-table-v8.scss';
import { PaginationV8 } from './PaginationV8';
import { useClampedPage } from './useClampedPage';

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    id: string;
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    cellClassName?: string;
    useDefaultCellStyle?: boolean;
    /**
     * A custom cell template for a segment cell. If provided, it will be used instead of the `cell` template.
     * - The template will receive the same context as the `cell` template, plus the `segmentData` property, which contains the data of the segment.
     * - The `getValue` function returns the value from the `segmentData` object by the `accessorKey` property of the column.
     * - The `row.original` contains the original data of the row (not the segment data)
     */
    segmentCell?: TData extends { segments: TableSegment<TData>[] }
      ? ColumnDefTemplate<CellContext<TData, TValue> & { segmentData: TData }>
      : never;
  }
}

export type TableSegment<T> = {
  id: string;
  name?: string;
  data: T;
};

type DataTableV8Props<T extends { segments?: TableSegment<T>[] }> = {
  id: string;
  isLoading?: boolean;
  data: T[];
  columns: ColumnDef<T, any>[];
  className?: string;
  tableStyle?: CSSProperties;
  rowCount?: number;
  pageCount?: number;
  paginationContainerId?: string;
  disablePagination?: boolean;
  state?: Partial<TableState>;
  enableHorizontalScrolling?: boolean;
  displayTableHeader?: boolean;
  displayBottomPagination?: boolean;
  manualPagination?: TableOptions<T>['manualPagination'];
  manualSorting?: TableOptions<T>['manualSorting'];
  enableMultiRowSelection?: TableOptions<T>['enableMultiRowSelection'];
  initialState?: TableOptions<T>['initialState'];
  onSortingChange?: TableOptions<T>['onSortingChange'];
  onGroupingChange?: TableOptions<T>['onGroupingChange'];
  onPaginationChange?: TableOptions<T>['onPaginationChange'];
  onRowSelectionChange?: TableOptions<T>['onRowSelectionChange'];
  onRowClick?: (row: Row<T>) => void;
  noDataMessage?: (() => ReactNode) | ReactNode;
  minWidth?: number;
  rowIdKey?: keyof T;
  size?: 'small' | 'large';
  stickyHeader?: boolean;
};

export const DataTableV8 = <T extends Record<string, any>>({
  id,
  columns,
  data,
  isLoading,
  enableHorizontalScrolling = true,
  enableMultiRowSelection = false,
  displayTableHeader = true,
  manualPagination = true,
  manualSorting = true,
  rowCount,
  className,
  tableStyle,
  pageCount,
  paginationContainerId,
  disablePagination = false,
  state,
  initialState,
  onSortingChange,
  onGroupingChange,
  onPaginationChange,
  onRowSelectionChange,
  onRowClick,
  noDataMessage,
  minWidth,
  rowIdKey,
  size = 'large',
  stickyHeader = false,
  displayBottomPagination = false,
}: DataTableV8Props<T>) => {
  const table = useReactTable<T>({
    meta: { id },
    columns: columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    defaultColumn: {
      enableSorting: false,
      enableGrouping: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      minSize: 210,
      // By default, the max size is Number.MAX_SAFE_INTEGER, which causes a bug with incorrect width in Mozilla Firefox. The value of 10000 is picked so it's not large enough to cause this bug, and the column has enough spare space to display its content.
      maxSize: 10000,
    },
    enableMultiRowSelection: enableMultiRowSelection,
    manualPagination: manualPagination,
    manualSorting: manualSorting,
    rowCount: rowCount,
    pageCount: pageCount,
    initialState: initialState,
    state: state,
    onSortingChange: onSortingChange,
    onGroupingChange: onGroupingChange,
    onPaginationChange: onPaginationChange,
    onRowSelectionChange: onRowSelectionChange,
    getRowId: (row, index) => {
      if (rowIdKey) return row[rowIdKey];
      return row?.id ?? String(index);
    },
  });
  useClampedPage(table);

  return (
    <>
      {paginationContainerId && <PaginationV8 containerId={paginationContainerId} table={table} size={size} />}

      {!paginationContainerId && !disablePagination && <TopPagination table={table} size={size} />}

      {enableHorizontalScrolling && (
        <HorizontalScroll>
          <TableComponent
            table={table}
            displayTableHeader={displayTableHeader}
            isLoading={isLoading}
            className={className}
            noDataMessage={noDataMessage}
            onRowClick={onRowClick}
            style={tableStyle}
            minWidth={minWidth}
            stickyHeader={stickyHeader}
            size={size}
          />
        </HorizontalScroll>
      )}

      {!enableHorizontalScrolling && (
        <TableComponent
          table={table}
          displayTableHeader={displayTableHeader}
          isLoading={isLoading}
          className={className}
          noDataMessage={noDataMessage}
          onRowClick={onRowClick}
          style={tableStyle}
          minWidth={minWidth}
          stickyHeader={stickyHeader}
          size={size}
        />
      )}

      {!disablePagination && <BottomPagination table={table} size={size} forceShow={displayBottomPagination} />}
    </>
  );
};

export const TableComponent = <T extends object>({
  table,
  isLoading,
  displayTableHeader = true,
  className,
  noDataMessage,
  onRowClick,
  style,
  minWidth,
  size = 'large',
  stickyHeader = false,
  onRowMouseEnter,
  onRowMouseLeave,
}: {
  table: Table<T>;
  isLoading?: boolean;
  displayTableHeader?: boolean;
  className?: string;
  noDataMessage?: (() => ReactNode) | ReactNode;
  onRowClick?: (row: Row<T>) => void;
  style?: CSSProperties;
  minWidth?: number;
  size?: 'small' | 'large';
  stickyHeader?: boolean;
  onRowMouseEnter?: (row: Row<T>) => void;
  onRowMouseLeave?: (row: Row<T>) => void;
}) => {
  const translate = useTranslate();
  const tableId = table.options.meta?.id;

  const getGridTemplateColumn = () => {
    let s = '';
    table.getAllColumns().forEach((col) => {
      s += `minmax(${col.columnDef.minSize}px, ${col.columnDef.maxSize}px) `;
    });
    return s;
  };

  return (
    <>
      <table
        className={classNames(
          'data-table-v8 grid w-full',
          `size-${size}`,
          { 'overflow-y-auto scrollbar scrollbar-thumb relative': stickyHeader },
          className,
        )}
        style={{ gridTemplateColumns: getGridTemplateColumn(), minWidth: minWidth, ...(style ?? {}) }}
        data-testid={tableId ? TEST_IDS.helpers.createTableId(tableId) : undefined}
      >
        {displayTableHeader && (
          <thead className='contents'>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr className='contents' role='row' key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th role='rowheader' key={header.id} className={classNames({ 'sticky top-0': stickyHeader })}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
        )}
        <tbody className='contents'>
          {isLoading &&
            Array.from(Array(table.getState().pagination.pageSize).keys()).map((_, i) => {
              return (
                <tr className='contents group' role='row' key={`tr-skeleton-${i}`}>
                  <td style={{ padding: 0, gridColumn: `span ${table.getAllColumns().length}` }}>
                    <Skeleton className='h-[67px] rounded-12' />
                  </td>
                </tr>
              );
            })}
          {!isLoading &&
            table.getRowCount() > 0 &&
            table
              .getRowModel()
              .rows.map((row) => (
                <TableRow
                  key={row.id}
                  row={row}
                  onClick={onRowClick}
                  onMouseEnter={onRowMouseEnter}
                  onMouseLeave={onRowMouseLeave}
                />
              ))}
        </tbody>
      </table>
      {!isLoading && table.getRowCount() === 0 && (
        <div
          className={`flex items-center justify-center text-mine-shaft font-medium text-sm text-center min-h-[350px] min-w-120`}
        >
          {typeof noDataMessage === 'function' ? noDataMessage() : noDataMessage ?? translate('table.noData')}
        </div>
      )}
    </>
  );
};

const TableRow = <T extends { segments?: TableSegment<T>[] }>({
  row,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  row: Row<T>;
  onClick?: (row: Row<T>) => void;
  onMouseEnter?: (row: Row<T>) => void;
  onMouseLeave?: (row: Row<T>) => void;
}) => {
  return (
    <>
      <tr
        className={classNames('contents group', {
          'cursor-pointer': !!onClick,
          selected: row.getIsSelected(),
        })}
        role='row'
        key={row.id}
        onClick={() => {
          row.toggleSelected();
          onClick?.(row);
        }}
        onMouseEnter={() => {
          onMouseEnter?.(row);
        }}
        onMouseLeave={() => {
          onMouseLeave?.(row);
        }}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              className={classNames(
                {
                  'w-full flex items-center justify-center text-center':
                    cell.column.columnDef.meta?.useDefaultCellStyle !== false,
                },
                cell.column.columnDef.meta?.cellClassName,
              )}
              data-testid={TEST_IDS.helpers.createTableCellId(cell.column.id)}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
      {row.original.segments?.map((segment) => {
        return (
          <SegmentRow segment={segment} key={`segment-${segment.id}`} name={segment.name ?? segment.id} row={row} />
        );
      })}
    </>
  );
};

export const TopPagination = ({ table, size = 'large' }: { table: Table<any>; size?: 'small' | 'large' }) => {
  return (
    <div className='flex max-h-12 gap-18px'>
      <PaginationV8
        table={table}
        showCountInfo={true}
        showPageSizeSelector={true}
        showPaginationBtns={true}
        size={size}
      />
    </div>
  );
};

const SegmentRow = <T,>({
  name,
  row,
  segment,
  onClick,
}: {
  name: string;
  row: Row<T>;
  segment: TableSegment<T>;
  onClick?: (row: Row<T>) => void;
}) => {
  const { getAllCells } = row;
  const cells = getAllCells();
  return (
    <tr
      className={classNames('contents group', {
        'cursor-pointer': !!onClick,
        selected: row.getIsSelected(),
      })}
      role='row'
      onClick={() => {
        row.toggleSelected();
        onClick?.(row);
      }}
    >
      {cells.map((cell, index) => {
        if (index === 0) return <SegmentNameCellV8 name={name} key={`cell-${index}`} />;
        return <SegmentCellV8 key={`cell-${index}`} cell={cell} segmentData={segment.data} />;
      })}
    </tr>
  );
};

export const BottomPagination = ({
  table,
  size = 'large',
  forceShow = false,
}: {
  table: Table<any>;
  size?: 'small' | 'large';
  // Bottom pagination is by default hidden on large screens
  forceShow: boolean;
}) => {
  return (
    <div className={classNames({ 'md:hidden': !forceShow }, 'flex justify-end mt-3')}>
      <PaginationV8
        table={table}
        showCountInfo={forceShow}
        showPageSizeSelector={forceShow}
        showPaginationBtns={true}
        size={size}
      />
    </div>
  );
};
