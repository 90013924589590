import { ReportBlockNodeItem } from '@va/types/report-block';
import { Button } from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';
import { useStartIntersectionObserverCtx } from '@va/util/misc';
import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { useReportBlockHeaderCtx } from '../report-block-header-ctx';
import { ReportBlockNodesTooltip } from './ReportBlockNodesTooltip';

export const ReportBlockVisibleNode = ({ node }: { node: ReportBlockNodeItem }) => {
  const { context } = useTooltipContext();
  const nodeRef = useRef<HTMLDivElement>(null);
  const { headerNodes, setHeaderNodes } = useReportBlockHeaderCtx();

  const closeTooltip = useCallback(() => context.onOpenChange(false), [context]);

  const intersectionCallback = useCallback<IntersectionObserverCallback>(
    (entries) => {
      const entry = entries[0];
      if (entry.intersectionRatio === 1 && !node.visible) {
        setHeaderNodes((prev) => prev.map((n) => (n.id !== node.id ? n : { ...n, visible: true })));
      } else if (entry.intersectionRatio < 1 && node.visible && headerNodes.length > 1) {
        setHeaderNodes((prev) => prev.map((n) => (n.id !== node.id ? n : { ...n, visible: false })));
      }
    },
    [headerNodes.length, node, setHeaderNodes],
  );

  useStartIntersectionObserverCtx(nodeRef, intersectionCallback);

  return (
    <div ref={nodeRef} className={classNames({ invisible: !node.visible })}>
      {node.headerComponent ?? (
        <ReportBlockNodesTooltip
          content={node.menuComponent?.(closeTooltip)}
          title={node.label}
          hideCloseButton={node.hideCloseButton}
        >
          <div>
            <Button icon={() => node.icon} />
          </div>
        </ReportBlockNodesTooltip>
      )}
    </div>
  );
};
