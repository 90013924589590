import { DEFAULT_REPORT_BLOCK_TYPE } from '@va/constants';
import { ExportFormat, useRequestExport } from '@va/dashboard/api-client/export';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsiteUrl } from '@va/dashboard/selectors/core';
import { usePrepareLatestVisitorsListRequests } from '@va/dashboard/shared/visitors';
import { useExportNotification } from '@va/dashboard/util-hooks';
import { useLocale } from '@va/localization';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useExportRbData = () => {
  const { execute } = useRequestExport();
  const websiteId = useSelector(getInstanceId);
  const { locale } = useLocale();
  const websiteUrl = useSelector(getWebsiteUrl);

  const { from, payload, until } = usePrepareLatestVisitorsListRequests();
  const { showErrorNotification, showExportStartedNotification } = useExportNotification();

  const startExport = useCallback(
    async (format: ExportFormat) => {
      try {
        await execute(websiteId, {
          from: moment(from).unix(),
          until: moment(until).unix(),
          locale: locale,
          exportType: DEFAULT_REPORT_BLOCK_TYPE.visitors.latestVisitorsTable,
          websiteUrl: websiteUrl,
          payload: payload,
          format: format,
        });

        showExportStartedNotification();
      } catch (error) {
        showErrorNotification();
      }
    },
    [
      execute,
      from,
      locale,
      payload,
      showErrorNotification,
      showExportStartedNotification,
      until,
      websiteId,
      websiteUrl,
    ],
  );

  return {
    downloadCSV: () => startExport(ExportFormat.csv),
    downloadXLSX: () => startExport(ExportFormat.xlsx),
  };
};
