export const CompareIcon = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='9' cy='8.25' r='7.5' stroke={color} strokeWidth='1.5' />
      <path
        opacity='0.25'
        d='M9 0C11.188 2.60921e-08 13.2865 0.869194 14.8336 2.41637C16.3808 3.96354 17.25 6.06196 17.25 8.25C17.25 10.438 16.3808 12.5365 14.8336 14.0836C13.2865 15.6308 11.188 16.5 9 16.5L9 8.25V0Z'
        fill={color}
      />
      <path d='M9 0.75V15.75' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
