import { IconProps } from '.';

export const ExportIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 13.5V2.87132L5.03033 6.09099L3.96967 5.03033L8.46967 0.530331L9 0L9.53033 0.530331L14.0303 5.03033L12.9697 6.09099L9.75 2.87132V13.5H8.25ZM1.5 10.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V10.5H18V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V10.5H1.5Z'
        fill={color}
      />
    </svg>
  );
};

export const ExportIconV2 = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 10.75V11.25C2 12.9069 3.34315 14.25 5 14.25H11C12.6569 14.25 14 12.9069 14 11.25V10.75H15.5V11.25C15.5 13.7353 13.4853 15.75 11 15.75H5C2.51472 15.75 0.5 13.7353 0.5 11.25V10.75H2Z'
        fill={color}
      />
      <circle opacity='0.075' cx='8' cy='8.25' r='5.25' fill={color} />
      <path d='M8 11.25V2.25M8 2.25L11 5.25M8 2.25L5 5.25' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
