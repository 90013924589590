import classNames from 'classnames';
import { IconProps } from '.';

export const Checkmark = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM8.78033 11.7803L13.2803 7.28033L12.2197 6.21967L8.25 10.1893L5.78033 7.71967L4.71967 8.78033L7.71967 11.7803L8.25 12.3107L8.78033 11.7803Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};

export function EmptyCheckmarkIcon({ className, color = '#C3C3C3' }: IconProps) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='10.875' stroke={color} strokeWidth='2.25' />
    </svg>
  );
}

export function FilledCheckmarkIcon({ color = '#06A25A', ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='12' cy='12' r='11.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM11.7803 14.7803L16.2803 10.2803L15.2197 9.21967L11.25 13.1893L8.78033 10.7197L7.71967 11.7803L10.7197 14.7803L11.25 15.3107L11.7803 14.7803Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        fill={color}
      />
    </svg>
  );
}
