import { FilledCheckIcon, RadioButtonThick } from '@va/icons';
import classNames from 'classnames';

const radioButtonStyles = {
  default: undefined,
  'wix-mini': 'w-18px h-18px',
};

export const DataPrivacyCardRadioButton = ({
  selected,
  variant,
}: {
  selected: boolean;
  variant: 'default' | 'wix-mini';
}) => {
  if (variant === 'wix-mini' && selected) {
    return <FilledCheckIcon className={classNames('absolute top-18px right-18px', radioButtonStyles[variant])} />;
  }

  return (
    <RadioButtonThick
      selected={selected}
      className={classNames('absolute top-18px right-18px', radioButtonStyles[variant])}
      innerCircleColor={!selected ? 'transparent' : undefined}
    />
  );
};
