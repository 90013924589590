import classNames from 'classnames';
import { IconProps } from '.';

export const DropdownIcon = ({ className, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='14'
      height='9'
      viewBox='0 0 14 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 1L7 7L13 1' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
