import { VerticalThreeDotIcon } from '@va/icons';
import { ReportBlockNodeItem } from '@va/types/report-block';
import { Button } from '@va/ui/design-system';
import { IntersectionObserverProvider } from '@va/util/misc';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useReportBlockHeaderCtx } from '../report-block-header-ctx';
import { ReportBlockNodesMenu } from './ReportBlockNodesMenu';
import { ReportBlockNodesTooltip } from './ReportBlockNodesTooltip';
import { ReportBlockVisibleNode } from './ReportBlockVisibleNode';

type ReportBlockNodesProps = {
  className?: string;
};

export const ReportBlockNodes = ({ className }: ReportBlockNodesProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { headerNodes } = useReportBlockHeaderCtx();

  if (headerNodes.length === 0) return null;

  return (
    <div
      className={classNames('flex overflow-hidden gap-4.5px justify-end grow shrink-0 basis-12 sm:basis-9', className)}
    >
      <div ref={containerRef} className='flex gap-4.5px justify-end overflow-hidden'>
        <IntersectionObserverProvider defaultOptions={{ root: containerRef?.current, threshold: 1 }}>
          {headerNodes.map((node) => (
            <ReportBlockVisibleNode key={node?.id} node={node} />
          ))}
        </IntersectionObserverProvider>
      </div>
      <MoreButton />
    </div>
  );
};

const MoreButton = () => {
  const { headerNodes } = useReportBlockHeaderCtx();
  const [selectedNode, setSelectedNode] = useState<ReportBlockNodeItem | null>(null);

  const menuNodes = useMemo(() => headerNodes.filter((node) => !node.visible), [headerNodes]);

  if (menuNodes.length === 0) return null;

  return (
    <ReportBlockNodesTooltip
      content={<ReportBlockNodesMenu nodes={menuNodes} selectedNode={selectedNode} setSelectedNode={setSelectedNode} />}
      hideCloseButton={selectedNode?.hideCloseButton}
    >
      <div className='shrink-0 min-w-fit'>
        <Button
          className='sm:w-9 sm:h-9 sm:p-9px sm:rounded-9px'
          color='tertiary'
          onClick={() => setSelectedNode(null)}
          icon={() => <VerticalThreeDotIcon />}
        />
      </div>
    </ReportBlockNodesTooltip>
  );
};
