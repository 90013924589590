import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { PropsWithChildren } from 'react';
import { AppliedSegmentsList } from './AppliedSegmentsList';

export const AddSegmentsTooltip = ({
  children,
  isOpen,
  openChange,
}: PropsWithChildren<{ isOpen: boolean; openChange: (open: boolean) => void }>) => {
  const translate = useTranslate();

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      placement='bottom'
      trigger='click'
      disabled={!isOpen}
      arrow={false}
      interactive
      open={isOpen}
      onOpenChange={openChange}
      tooltipClassNames='flex flex-col bg-white rounded-15 p-1.5 w-screen max-w-sm shadow-xl max-h-[450px] overflow-hidden'
      content={
        <>
          <div className='p-9px pl-4'>
            <Paragraph weight={fontWeights.medium}>{translate('reportBlockNode.segments')}</Paragraph>
          </div>
          <div className='overflow-hidden flex flex-col'>
            <AppliedSegmentsList />
          </div>
          <CloseButton />
        </>
      }
      zIndex={'var(--zIndex-filters-dropdown)'}
    >
      {children}
    </TooltipWrapper>
  );
};

const CloseButton = () => {
  const translate = useTranslate();
  const { context } = useTooltipContext();
  return (
    <Button
      data-testid={TEST_IDS.generic.buttons.close}
      onClick={() => context.onOpenChange(false)}
      color='tertiary'
      className='w-full mt-[3px] shrink-0 py-9px'
      textClasses='text-12 leading-18'
      text={translate('button.close')}
      size='small'
    />
  );
};
