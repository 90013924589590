import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useFiltersContext } from '@va/shared/feature-filters';
import { PropsWithChildren, createContext, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { useDeleteVisitorSegment, useGetVisitorSegments } from './api';
import { RightSideView, VisitorSegment, segmentsSelectOptionsEnum } from './types';
import { hasConditions } from './useVisitorSegmentsColumns';

type VisitorSegmentsContext = {
  isSegmentSelected: (segmentId: string) => boolean;
  toggleSegmentSelection: (segmentId: string) => void;
  rightSideView: RightSideView;
  onActionBtnClick: (segmentId: string) => void;
  selectedSegmentsIds: string[];
  segmentToEdit: VisitorSegment | undefined;
  segments: VisitorSegment[] | [];
  selectAllSegments: () => void;
  selectedBulkOption: segmentsSelectOptionsEnum | null;
  setSelectedBulkOption: (option: segmentsSelectOptionsEnum | null) => void;
  deleteSegments: (ids: string[]) => void;
  totalEditCount: number;
  clearAllSelectedSegments: () => void;
  isLoading: boolean;
  totalCount: number;
  setTableQuery: any;
  mutateSegments: () => void;
};

const VisitorSegmentsContext = createContext({} as VisitorSegmentsContext);

const VisitorSegmentsContextProvider = forwardRef(({ children }: PropsWithChildren<{}>, ref) => {
  const translate = useTranslate();
  const [selectedSegmentsIds, setSelectedSegmentsIds] = useState<string[]>([]);
  const [rightSideView, setRightSideView] = useState<RightSideView>(RightSideView.CREATE);
  const [selectedBulkOption, setSelectedBulkOption] = useState<segmentsSelectOptionsEnum | null>(null);
  const [tableQuery, setTableQuery] = useState<{ order: string | undefined }>({ order: undefined });
  const { showErrorNotification, showSuccessNotification, showInfoNotification } = useAddNotification();
  const { data: segments = [], isLoading, mutate: mutateSegments } = useGetVisitorSegments(tableQuery);
  const { execute: executeDeleteSegments } = useDeleteVisitorSegment();

  const { deleteAllAppliedFilters, applyConditions } = useFiltersContext();

  const clearAllSelectedSegments = useCallback(() => {
    setSelectedSegmentsIds([]);
    setSelectedBulkOption(null);
    setRightSideView(RightSideView.CREATE);
  }, []);

  const deleteSegments = useCallback(
    async (ids: string[]) => {
      try {
        const response = await executeDeleteSegments(ids);

        if (response.data.notDeletedSegments.length !== 0) {
          showInfoNotification(
            translate('visitorSegments.deleteNotification.notAllDeleted', {
              count: response.data.notDeletedSegments.length,
            }),
          );
        } else {
          showSuccessNotification();
        }

        deleteAllAppliedFilters();
      } catch {
        showErrorNotification();
      } finally {
        mutateSegments();
        clearAllSelectedSegments();
      }
    },
    [
      clearAllSelectedSegments,
      deleteAllAppliedFilters,
      executeDeleteSegments,
      mutateSegments,
      showErrorNotification,
      showInfoNotification,
      showSuccessNotification,
      translate,
    ],
  );

  const totalCount = useMemo(() => {
    return segments?.length ?? 0;
  }, [segments?.length]);

  const totalEditCount = useMemo(() => {
    if (!segments) return 0;
    return segments.filter((segment) => segment.id !== 'default').length;
  }, [segments]);

  const isSegmentSelected = useCallback(
    (segmentId: string) => {
      return selectedSegmentsIds.includes(segmentId);
    },
    [selectedSegmentsIds],
  );

  const selectAllSegments = useCallback(() => {
    const nonDefaultSegments = segments.filter((segment) => segment.id !== 'default');
    setSelectedSegmentsIds(nonDefaultSegments.map((segment) => segment.id));
  }, [segments]);

  const toggleSegmentSelection = useCallback(
    (segmentId: string) => {
      if (segmentId === 'default') return;
      if (isSegmentSelected(segmentId)) {
        setSelectedSegmentsIds((prev) => prev.filter((crtSegmentId) => segmentId !== crtSegmentId));
      } else {
        setSelectedSegmentsIds((prev) => [...prev, segmentId]);
      }
      setRightSideView(RightSideView.CREATE);
      setSelectedBulkOption(null);
    },
    [isSegmentSelected],
  );

  //Return selected segment if in edit mode and undefined otherwise
  const segmentToEdit = useMemo(() => {
    if (rightSideView === RightSideView.CREATE) return undefined;
    return segments.find((item: VisitorSegment) => item?.id === selectedSegmentsIds[0]);
  }, [rightSideView, segments, selectedSegmentsIds]);

  //Create a filter from each segment condition and apply them
  const updateSegmentFilters = useCallback(
    (segmentId: string) => {
      const currentSegment = segments.find((item: VisitorSegment) => item?.id === segmentId);

      if (!currentSegment) return;

      if (!hasConditions(currentSegment?.conditions)) {
        deleteAllAppliedFilters();
        return;
      }

      applyConditions(currentSegment.conditions);
    },
    [applyConditions, deleteAllAppliedFilters, segments],
  );

  const onActionBtnClick = useCallback(
    (segmentId: string) => {
      //If current segment is toggled, enter create mode and reset filters
      const currentSegmentSelected = selectedSegmentsIds[0];
      if (currentSegmentSelected === segmentId) {
        setRightSideView(RightSideView.CREATE);
        setSelectedSegmentsIds([]);
        deleteAllAppliedFilters();
        return;
      }

      setRightSideView(RightSideView.EDIT);
      setSelectedSegmentsIds([segmentId]);
      updateSegmentFilters(segmentId);
    },
    [deleteAllAppliedFilters, selectedSegmentsIds, updateSegmentFilters],
  );

  return (
    <VisitorSegmentsContext.Provider
      value={{
        isLoading,
        isSegmentSelected,
        toggleSegmentSelection,
        rightSideView,
        onActionBtnClick,
        selectedSegmentsIds,
        segmentToEdit,
        segments,
        selectAllSegments,
        selectedBulkOption,
        setSelectedBulkOption,
        deleteSegments,
        totalEditCount,
        totalCount,
        clearAllSelectedSegments,
        setTableQuery,
        mutateSegments,
      }}
    >
      {children}
    </VisitorSegmentsContext.Provider>
  );
});

export default VisitorSegmentsContextProvider;

export const useVisitorSegmentsContext = () => useContext(VisitorSegmentsContext);
