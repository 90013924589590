import { FallbackIconCircle, FallbackIconRectangle } from '@va/icons';
import '@va/styles/index.scss';
import { getFlagUrl, isValidCountryCode } from '@va/util/helpers';
import classNames from 'classnames';
import { FC } from 'react';
import { EnglishFlagIcon } from './EnglishFlagIcon';

type FlagSize = '1x1' | '4x3';

const FallbackFlag: FC<{ size: FlagSize; className?: string; color?: string }> = ({ size, className, color }) => {
  if (size === '1x1') {
    return <FallbackIconCircle className={className} color={color} />;
  }

  return <FallbackIconRectangle className={classNames(className, 'w-22px h-[17px]')} color={color} />;
};

function getStyles(countryCode: string, size: FlagSize) {
  if (!countryCode || !isValidCountryCode(countryCode)) {
    return {};
  }

  return {
    backgroundImage: `url(${getFlagUrl(countryCode.toLowerCase(), size || '4x3')})`,
  };
}

export function Flag(props: {
  countryCode: string;
  size?: FlagSize;
  className?: string;
  useEnglishLanguageFlag?: boolean;
  fallbackIconColor?: string;
}) {
  const { countryCode, size = '4x3', className, useEnglishLanguageFlag, fallbackIconColor } = props;

  const styles = getStyles(countryCode, size);
  const classes = ['flag', 'flag-' + size, className, 'ignore-dark-mode'];

  if (!isValidCountryCode(countryCode)) {
    return <FallbackFlag size={size} className={classNames(classes)} color={fallbackIconColor} />;
  }

  if (useEnglishLanguageFlag && countryCode.toLowerCase() === 'us') {
    return (
      <span className='new-english-language-flag-wrapper'>
        <EnglishFlagIcon size={size} className={classNames('new-english-language-flag', classes.join(' '))} />
      </span>
    );
  }

  return <span className={classes.join(' ')} style={styles} />;
}
