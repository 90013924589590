import { CloseIconWithCircle, FilledCheckIcon } from '@va/icons';
import { Heading3, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { setResizeObserver } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import './multi-step-wizard.scss';

const lineClasses = 'line w-full h-1 relative top-1/2 border-b-2 border-dashed';

export type MultiStepWizardStep = {
  label: React.ReactNode;
  component: ReactNode;
  hasError?: boolean;
  isFinalized?: boolean;
  subInfo?: string;
  smallLabel?: boolean;
  canReturn?: boolean;
};

export type MultiStepWizardProps = {
  steps: MultiStepWizardStep[];
  activeStep: number;
  onStepChange?: (step: number) => void;
  variant?: 'default' | 'wix-mini';
};

const styles = {
  default: {
    stepsSpacing: 0.5,
    labelColor: {
      active: '!text-text-primary',
      inactive: 'text-gray-silver',
      completed: 'text-primary',
      error: '!text-negative',
    },
    smallLabelStyle: 'mt-18px text-center',
    smallLabelFontSize: paragraphSizes.large,
    smallLabelFontWeight: fontWeights.normal,
    labelFontWeight: fontWeights.semibold,
    labelContainerClass: '',
    line: {
      default: 'first:mr-30px last:ml-30px',
      active: 'border-primary',
      inactive: 'border-gray-dusty',
    },
  },
  'wix-mini': {
    stepsSpacing: 0.2,
    labelColor: {
      active: '!text-primary',
      inactive: 'text-text-secondary',
      completed: 'text-primary',
      error: '!text-negative',
    },
    smallLabelStyle: 'mt-8 text-center',
    smallLabelFontSize: paragraphSizes.medium,
    smallLabelFontWeight: fontWeights.medium,
    labelFontWeight: fontWeights.medium,
    labelContainerClass: 'mt-1.5',
    line: {
      default: 'first:mr-2 last:ml-2',
      active: 'border-gray-geyser',
      inactive: '!border-gray-geyser',
    },
  },
};

export const MultiStepWizard: FC<MultiStepWizardProps> = ({ steps, activeStep, onStepChange, variant = 'default' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowDimensions();
  const containerRef = useRef<HTMLDivElement>(null);
  const [itemWidth, setItemWidth] = useState(0);

  const stepsSpacing = useMemo(() => {
    if (isMobile) return 0.75;

    return styles[variant].stepsSpacing;
  }, [isMobile, variant]);

  const onResizeCallback = useCallback(() => {
    if (!ref.current) return;
    if (isMobile) {
      setItemWidth(ref.current.clientWidth * stepsSpacing);
      return;
    }
    setItemWidth(ref.current.clientWidth * stepsSpacing);
  }, [isMobile, stepsSpacing]);

  const translateBy = useMemo(() => {
    const containerWidth = containerRef.current?.clientWidth ?? 0;
    return containerWidth * 0.5 - itemWidth * 0.5 - itemWidth * activeStep;
  }, [activeStep, itemWidth]);

  useEffect(() => {
    setResizeObserver(containerRef, onResizeCallback);
  }, [onResizeCallback]);

  return (
    <div ref={containerRef}>
      <div
        ref={ref}
        id='multi-step'
        className='flex flex-row flex-nowrap gap-1 transition-transform duration-500'
        style={{
          transform: `translateX(${translateBy}px)`,
        }}
      >
        {steps.map((step, index) => {
          const { hasError, label, isFinalized, subInfo, smallLabel } = step;
          const isActive = activeStep === index;
          const isCompleted = activeStep > index || isFinalized;
          const isNextActive = activeStep >= index + 1;
          const canJumpToStep = activeStep > index && steps[index + 1]?.canReturn;

          const shouldShowStepLabel = () => {
            if ((isMobile || variant === 'wix-mini') && !isActive) return false;
            return true;
          };

          const labelStyles = 'mx-2 pt-3 text-center break-words';
          const labelColorStyles = classNames({
            [styles[variant].labelColor.inactive]: !isActive && !isCompleted,
            [styles[variant].labelColor.active]: isActive,
            [styles[variant].labelColor.completed]: isCompleted,
            [styles[variant].labelColor.error]: hasError,
          });

          const handleStepChange = () => {
            if (canJumpToStep) {
              onStepChange?.(index);
            }
          };

          return (
            <div key={index} className='step flex flex-col items-center shrink-0' style={{ width: `${itemWidth}px` }}>
              <div className='w-full flex justify-center'>
                <div
                  className={classNames(lineClasses, styles[variant].line.default, {
                    [styles[variant].line.inactive]: !isActive && !isNextActive,
                    [styles[variant].line.active]: isActive || isNextActive,
                  })}
                />
                <StepStatusIcon
                  className={classNames({
                    'cursor-pointer': canJumpToStep,
                  })}
                  index={index}
                  hasError={!!hasError}
                  isActive={isActive}
                  isCompleted={!!isCompleted}
                  onClick={handleStepChange}
                  variant={variant}
                />
                <div
                  className={classNames(lineClasses, styles[variant].line.default, {
                    [styles[variant].line.inactive]: !isNextActive,
                    [styles[variant].line.active]: isNextActive,
                  })}
                />
              </div>
              {shouldShowStepLabel() && (
                <div
                  className={classNames('flex flex-col', styles[variant].labelContainerClass, {
                    'cursor-pointer': canJumpToStep,
                  })}
                >
                  {smallLabel ? (
                    <Paragraph
                      className={styles[variant].smallLabelStyle}
                      size={styles[variant].smallLabelFontSize}
                      weight={styles[variant].smallLabelFontWeight}
                      onClick={handleStepChange}
                      colorClassName={labelColorStyles}
                    >
                      {label}
                    </Paragraph>
                  ) : (
                    <Heading3
                      className={labelStyles}
                      weight={styles[variant].labelFontWeight}
                      colorClassName={labelColorStyles}
                      onClick={handleStepChange}
                    >
                      {label}
                    </Heading3>
                  )}
                  {subInfo && isActive && (
                    <Paragraph weight={fontWeights.medium} className='mt-1' colorClassName={labelColorStyles}>
                      {subInfo}
                    </Paragraph>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div>{steps[activeStep] ? steps[activeStep].component : <Redirect to={'/error'} />}</div>
    </div>
  );
};

const stepStatusIconStyles = {
  default: {
    size: 'w-12 h-12 text-xl',
    active: 'bg-gray-charcoal',
    inactive: 'bg-gray-silver',
    errorColor: 'var(--color-negative)',
    completedColor: 'var(--color-primary)',
  },
  'wix-mini': {
    size: 'w-6 h-6 text-xs',
    active: 'bg-primary',
    inactive: 'bg-gray-geyser !w-18px !h-18px my-[3px]',
    errorColor: 'var(--color-negative)',
    completedColor: 'var(--color-primary)',
  },
};

const StepStatusIcon = ({
  hasError,
  isActive,
  isCompleted,
  index,
  className,
  onClick,
  variant = 'default',
}: {
  index: number;
  hasError: boolean;
  isActive: boolean;
  isCompleted: boolean;
  className?: string;
  onClick?: () => void;
  variant?: 'default' | 'wix-mini';
}) => {
  if (hasError)
    return (
      <CloseIconWithCircle
        className={classNames('shrink-0', className, stepStatusIconStyles[variant].size)}
        color={stepStatusIconStyles[variant].errorColor}
        onClick={onClick}
      />
    );
  if (isCompleted)
    return (
      <FilledCheckIcon
        className={classNames('shrink-0', className, stepStatusIconStyles[variant].size)}
        color={stepStatusIconStyles[variant].completedColor}
        onClick={onClick}
      />
    );

  return (
    <div
      className={classNames(
        'font-bold flex items-center justify-center shrink-0 rounded-full text-white',
        className,
        stepStatusIconStyles[variant].size,
        {
          [stepStatusIconStyles[variant].inactive]: !isActive,
          [stepStatusIconStyles[variant].active]: isActive,
        },
      )}
      onClick={onClick}
    >
      {index + 1}
    </div>
  );
};
