import { useGetWebsiteContributors } from '@va/dashboard/api-client/contributors.api';
import { useFullNameById } from '@va/dashboard/util-hooks';
import { Paragraph, fontWeights } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getInitials, stringToHslColor } from '@va/util/helpers';

export const AuthorCell = ({ createdBy }: { createdBy?: string }) => {
  const { data: websiteContributors } = useGetWebsiteContributors();
  const segmentCreatorName = useFullNameById(createdBy, websiteContributors);
  const colorValue = stringToHslColor(segmentCreatorName ?? 'N/A', '50', '60');

  return (
    <TooltipWrapper content={segmentCreatorName}>
      <div
        className='w-12 h-12 rounded-full flex items-center justify-center text-white'
        style={{ backgroundColor: colorValue }}
      >
        <Paragraph weight={fontWeights.medium}>{getInitials(segmentCreatorName ?? 'N/A')}</Paragraph>
      </div>
    </TooltipWrapper>
  );
};
