import { useTrafficChannelValues } from '@va/dashboard/api-client/traffic-channels';
import { useTranslate } from '@va/localization';
import { MultiSelectFilter, SingleSelectFilter } from '@va/shared/feature-filters';
import { FilterLogicalOperators, FilterOperators } from '@va/types/filters';
import { useMemo } from 'react';

const ALL_TRAFFIC_CHANNEL_VALUES = 'ALL_TRAFFIC_CHANNEL_VALUES';

export const useNewDirectChannelFilter = () => {
  const translate = useTranslate();
  return useMemo(() => {
    return new SingleSelectFilter({
      id: 'traffic-channel-direct',
      name: 'traffic-channel-direct',
      apiFieldNameMapper: () => 'trafficChannel',
      label: translate('filters.trafficChannel.direct'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      valueMapper: (value) => {
        return [{ type: 'direct', category: value[0] === ALL_TRAFFIC_CHANNEL_VALUES ? [] : value }];
      },
      inputProps: {
        useOptions: () => ({ data: [{ value: ALL_TRAFFIC_CHANNEL_VALUES, label: translate('filters.labels.ALL') }] }),
      },
    });
  }, [translate]);
};

export const useNewSocialChannelFilter = () => {
  const translate = useTranslate();

  return useMemo(() => {
    return new MultiSelectFilter({
      id: 'traffic-channel-social',
      name: 'traffic-channel-social',
      apiFieldNameMapper: () => 'trafficChannel',
      label: translate('filters.trafficChannel.socialMedia'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      valueMapper: (value) => {
        return [{ type: 'social', category: value[0] === ALL_TRAFFIC_CHANNEL_VALUES ? [] : value }];
      },
      optionsLogicalOperator: FilterLogicalOperators.or,
      inputProps: {
        useOptions: () => useTrafficChannelValues('social'),
        allOption: { value: ALL_TRAFFIC_CHANNEL_VALUES, label: translate('filters.labels.ALL'), allOption: true },
        showSearchInput: true,
      },
    });
  }, [translate]);
};

export const useNewOrganicChannelFilter = () => {
  const translate = useTranslate();

  return useMemo(() => {
    return new MultiSelectFilter({
      id: 'traffic-channel-search',
      name: 'traffic-channel-search',
      apiFieldNameMapper: () => 'trafficChannel',
      label: translate('filters.trafficChannel.organicSearch'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      valueMapper: (value) => {
        return [{ type: 'search', category: value[0] === ALL_TRAFFIC_CHANNEL_VALUES ? [] : value }];
      },
      optionsLogicalOperator: FilterLogicalOperators.or,
      inputProps: {
        useOptions: () => useTrafficChannelValues('search'),
        allOption: { value: ALL_TRAFFIC_CHANNEL_VALUES, label: translate('filters.labels.ALL'), allOption: true },
        showSearchInput: true,
      },
    });
  }, [translate]);
};

export const useNewReferrerChannelFilter = () => {
  const translate = useTranslate();

  return useMemo(() => {
    return new MultiSelectFilter({
      id: 'traffic-channel-referrals',
      name: 'traffic-channel-referrals',
      label: translate('filters.trafficChannel.referrals'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      optionsLogicalOperator: FilterLogicalOperators.or,
      apiFieldNameMapper: (values) => {
        if (values[0] === 'unknown') {
          return 'trafficChannel';
        }

        return 'referrerUrl';
      },
      valueMapper: (value, _operator) => {
        if (value[0] === 'unknown') {
          // Filter for all traffic except "direct" - this means a referrer exists
          return [
            { type: 'social', category: [] },
            { type: 'paid', category: [] },
            { type: 'email', category: [] },
            { type: 'search', category: [] },
            { type: 'unknown', category: [] },
          ];
        }
        return value;
      },
      inputProps: {
        useOptions: () => useTrafficChannelValues('unknown'),
        allOption: { value: 'unknown', label: translate('filters.labels.ALL'), allOption: true },
        showSearchInput: true,
      },
    });
  }, [translate]);
};

export const useNewPaidChannelFilter = () => {
  const translate = useTranslate();

  return useMemo(() => {
    return new MultiSelectFilter({
      id: 'traffic-channel-paid',
      name: 'traffic-channel-paid',
      label: translate('filters.trafficChannel.paid'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      valueMapper: (value) => {
        return [{ type: 'paid', category: value[0] === ALL_TRAFFIC_CHANNEL_VALUES ? [] : value }];
      },
      apiFieldNameMapper: () => 'trafficChannel',
      optionsLogicalOperator: FilterLogicalOperators.or,
      inputProps: {
        useOptions: () => useTrafficChannelValues('paid'),
        allOption: { value: ALL_TRAFFIC_CHANNEL_VALUES, label: translate('filters.labels.ALL'), allOption: true },
        showSearchInput: true,
      },
    });
  }, [translate]);
};

export const useNewEmailChannelFilter = () => {
  const translate = useTranslate();

  return useMemo(() => {
    return new MultiSelectFilter({
      id: 'traffic-channel-email',
      name: 'traffic-channel-email',
      apiFieldNameMapper: () => 'trafficChannel',
      label: translate('filters.trafficChannel.email'),
      operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      valueMapper: (value) => {
        return [{ type: 'email', category: value[0] === ALL_TRAFFIC_CHANNEL_VALUES ? [] : value }];
      },
      optionsLogicalOperator: FilterLogicalOperators.or,
      inputProps: {
        useOptions: () => useTrafficChannelValues('email'),
        allOption: { value: ALL_TRAFFIC_CHANNEL_VALUES, label: translate('filters.labels.ALL'), allOption: true },
        showSearchInput: true,
      },
    });
  }, [translate]);
};
