import { IconProps } from '.';

export const CogIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 1.5H9.75V1.75734C9.75 3.76188 12.1736 4.76575 13.591 3.34833L13.7729 3.16637L14.8336 4.22703L14.6516 4.40901C13.2342 5.82642 14.2381 8.25 16.2426 8.25H16.5V9.75H16.2426C14.2381 9.75 13.2342 12.1736 14.6517 13.591L14.8336 13.773L13.773 14.8336L13.591 14.6516C12.1736 13.2342 9.75 14.2381 9.75 16.2426V16.5H8.25V16.2426C8.25 14.2381 5.82644 13.2342 4.40901 14.6516L4.22701 14.8336L3.16635 13.773L3.34833 13.591C4.76576 12.1736 3.76187 9.75 1.75734 9.75H1.5V8.25H1.75736C3.7619 8.25 4.76578 5.82643 3.34835 4.40901L3.16637 4.22703L4.22704 3.16637L4.40901 3.34834C5.82643 4.76576 8.25 3.7619 8.25 1.75735V1.5ZM13.7729 1.04505L12.7123 2.10571L12.5303 2.28767C12.0579 2.76014 11.25 2.42552 11.25 1.75734V1.5V0H9.75H8.25H6.75V1.5V1.75735C6.75 2.42553 5.94214 2.76016 5.46967 2.28768L5.2877 2.10571L4.22704 1.04505L3.16638 2.10571L2.10571 3.16637L1.04505 4.22703L2.10571 5.28769L2.28769 5.46967C2.76017 5.94214 2.42554 6.75 1.75736 6.75H1.5H0V8.25V9.75V11.25H1.5H1.75734C2.42552 11.25 2.76014 12.0579 2.28767 12.5303L2.10569 12.7123L1.04503 13.773L2.10569 14.8336L3.16635 15.8943L4.22701 16.955L5.28767 15.8943L5.46967 15.7123C5.94214 15.2398 6.75 15.5744 6.75 16.2426V16.5V18H8.25H9.75H11.25V16.5V16.2426C11.25 15.5745 12.0579 15.2398 12.5303 15.7123L12.7123 15.8943L13.773 16.955L14.8336 15.8943L15.8943 14.8336L16.955 13.773L15.8943 12.7123L15.7123 12.5303C15.2398 12.0579 15.5745 11.25 16.2426 11.25H16.5H18V9.75V8.25V6.75H16.5H16.2426C15.5744 6.75 15.2398 5.94214 15.7123 5.46967L15.8943 5.28769L16.9549 4.22703L15.8943 3.16637L14.8336 2.10571L13.7729 1.04505ZM11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9ZM12.75 9C12.75 11.0711 11.0711 12.75 9 12.75C6.92893 12.75 5.25 11.0711 5.25 9C5.25 6.92893 6.92893 5.25 9 5.25C11.0711 5.25 12.75 6.92893 12.75 9Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};

export const CogIconV2 = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 0.75H10V1.9489C10 2.93781 11.1956 3.43305 11.8949 2.73379L12.7426 1.88604L14.864 4.00736L14.0162 4.85511C13.3169 5.55437 13.8122 6.75 14.8011 6.75H16V9.75H14.8011C13.8122 9.75 13.3169 10.9456 14.0162 11.6449L14.864 12.4926L12.7426 14.614L11.8949 13.7662C11.1956 13.0669 10 13.5622 10 14.5511V15.75H7V14.5511C7 13.5622 5.80437 13.067 5.10511 13.7662L5.63544 14.2965L5.10511 13.7662L4.25736 14.614L2.13604 12.4926L2.98379 11.6449C3.68305 10.9456 3.18781 9.75 2.1989 9.75H1V6.75H2.1989C3.18781 6.75 3.68305 5.55437 2.98379 4.85511L2.13604 4.00736L4.25736 1.88604L5.10511 2.73379C5.80437 3.43305 7 2.93781 7 1.9489V0.75Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <circle opacity='0.25' cx='8.5' cy='8.25' r='3.375' stroke={color} strokeWidth='0.75' />
      <circle cx='8.5' cy='8.25' r='2.25' fill={color} opacity='0.75' />
    </svg>
  );
};
