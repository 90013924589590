import { PrivacyLockIcon } from '@va/icons';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import classNames from 'classnames';
import { useCallback } from 'react';

type DataPrivacyLevelIconProps = {
  selected: boolean;
  value: PrivacyLevelsEnum;
  variant?: 'default' | 'wix-mini';
};

const styles = {
  default: {
    iconContainer: {
      default: 'h-60px w-60px my-2 p-1',
      base: 'bg-white',
      selected: 'bg-blue-pure',
    },
    levelIconColor: {
      base: '#2478F0',
      selected: '#FFFFFF',
      inactive: '#F0F0F0',
      selectedInactive: '#6DA8FC',
    },
    levelIconLine: {
      topLeft: 'border-t-4 border-l-4',
      topRight: 'border-t-4 border-r-4',
      bottomLeft: 'border-b-4 border-l-4',
      bottomRight: 'border-b-4 border-r-4',
    },
    iconColor: {
      base: '#969696',
      selected: '#FFFFFF',
    },
  },
  'wix-mini': {
    iconContainer: {
      default: 'h-42px w-42px',
      base: 'bg-white',
      selected: 'bg-primary',
    },
    levelIconColor: {
      base: 'var(--color-primary)',
      selected: 'var(--color-primary)',
      inactive: '#FFFFFF',
      selectedInactive: 'var(--color-primary)',
    },
    levelIconLine: {
      topLeft: 'border-t-2.5 border-l-2.5',
      topRight: 'border-t-2.5 border-r-2.5',
      bottomLeft: 'border-b-2.5 border-l-2.5',
      bottomRight: 'border-b-2.5 border-r-2.5',
    },
    iconColor: {
      base: '#969696',
      selected: '#FFFFFF',
    },
  },
};

export const DataPrivacyLevelIcon = ({ selected, value, variant = 'default' }: DataPrivacyLevelIconProps) => {
  const levelIconColor = useCallback(
    (level: PrivacyLevelsEnum) => {
      if (selected) {
        if (value >= level) return styles[variant].levelIconColor.selected;
        return styles[variant].levelIconColor.selectedInactive;
      }
      if (value >= level) return styles[variant].levelIconColor.base;
      return styles[variant].levelIconColor.inactive;
    },
    [selected, value, variant],
  );

  return (
    <div
      className={classNames(
        'relative rounded-full flex justify-center items-center',
        styles[variant].iconContainer.default,
        {
          [styles[variant].iconContainer.base]: !selected,
          [styles[variant].iconContainer.selected]: selected,
        },
      )}
    >
      <div
        className={classNames('grid grid-cols-2 w-full h-full', {
          'gap-0.5': variant === 'default' || (variant === 'wix-mini' && value < PrivacyLevelsEnum.completePrivacy),
        })}
      >
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.completePrivacy) }}
          className={classNames('border-primary rounded-tl-full', styles[variant].levelIconLine.topLeft)}
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.defaultPrivacy) }}
          className={classNames('border-primary rounded-tr-full', styles[variant].levelIconLine.topRight)}
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.GDPRMode) }}
          className={classNames('border-primary rounded-bl-full', styles[variant].levelIconLine.bottomLeft)}
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.basicPrivacy) }}
          className={classNames('border-primary rounded-br-full', styles[variant].levelIconLine.bottomRight)}
        />
      </div>
      <PrivacyLockIcon
        color={selected ? styles[variant].iconColor.selected : styles[variant].iconColor.base}
        className='absolute'
        level={value}
      />
    </div>
  );
};
