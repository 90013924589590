import { EVENTS } from '@va/constants';
import { VisitorSegment, useGetVisitorSegments } from '@va/dashboard/feature/visitor-segments';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { SessionStorage, storeSegmentIds } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import {
  EventBus,
  StorageKeysManager,
  useCustomizationContext,
  useEventsContext,
  usePermissionsProvider,
} from '@va/util/misc';
import { isArray } from 'lodash';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type SegmentsContextType = {
  segments: Array<VisitorSegment>;
  isLoading: boolean;
  selectedSegments: Array<VisitorSegment>;
  isSegmentSelected: (segmentId: string) => boolean;
  toggleSegmentSelection: (id: string) => void;
  isExpanded: boolean;
  setIsExpanded: (v: boolean) => void;
  getSegmentName: (segmentId: string) => string | undefined;
};

const SegmentsContext = createContext<SegmentsContextType>({} as SegmentsContextType);

const SegmentsContextProvider: FC<
  PropsWithChildren<{
    reportBlockId: string;
  }>
> = ({ children, reportBlockId }) => {
  const { getCustomValue } = useCustomizationContext();
  const { isMediumDevice } = useWindowDimensions();
  const { canEditSegments } = usePermissionsProvider();
  const websiteId = useSelector(getInstanceId);

  const sessionStoragePersistenceActive = useMemo(
    () => getCustomValue('sessionStoragePersistenceForSegments', true),
    [getCustomValue],
  );

  const getInitialSegmentIds = useCallback(() => {
    const customInitialSegmentIds = getCustomValue('initialSelectedSegmentsIds', undefined);
    if (customInitialSegmentIds) return customInitialSegmentIds;

    const storedSegmentIds = SessionStorage.getItem(
      StorageKeysManager.getReportSegmentsKey({ websiteId, reportBlockId }),
    );

    if (!storedSegmentIds || storedSegmentIds === 'null') return;

    try {
      const parsedIds = JSON.parse(storedSegmentIds) as string[];
      if (!isArray(parsedIds)) {
        throw new Error('invalid ids');
      }

      parsedIds.forEach((item) => {
        if (typeof item != 'string') {
          throw new Error('invalid ids');
        }
      });

      return parsedIds;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [getCustomValue, reportBlockId, websiteId]);

  const [selectedSegmentsIds, setSelectedSegmentsIds] = useState<string[]>(getInitialSegmentIds() ?? []);

  const translate = useTranslate();

  const { showErrorNotification } = useAddNotification();

  const [isExpanded, setIsExpanded] = useState<boolean>(getCustomValue('initialSegmentsExpanded', false));
  const { data: segments = [], isLoading } = useGetVisitorSegments();

  const { localEventBus } = useEventsContext();

  const isSegmentSelected = useCallback(
    (segmentId: string) => selectedSegmentsIds.includes(segmentId),
    [selectedSegmentsIds],
  );

  const selectedSegments = useMemo(
    () => segments.filter((item) => selectedSegmentsIds.includes(item.id)),
    [segments, selectedSegmentsIds],
  );

  const sendSegmentsChangedEvent = useCallback(
    (newIds: string[]) => {
      localEventBus?.dispatch(EVENTS.selectedSegmentsChanged, newIds);
      if (sessionStoragePersistenceActive) {
        storeSegmentIds(websiteId, reportBlockId, newIds);
      }
    },
    [localEventBus, reportBlockId, sessionStoragePersistenceActive, websiteId],
  );

  useEffect(() => {
    const l = EventBus.addListener(EVENTS.collapseSegments, () => {
      setIsExpanded(false);
    });

    return () => {
      l.removeListener();
    };
  }, []);

  const toggleSegmentSelection = useCallback(
    (id: string) => {
      if (!canEditSegments) {
        showErrorNotification(
          4000,
          getCustomValue('disabledSegmentsMessage', translate('all.defaultWarnnings.cantEditSegments')),
        );
        return;
      }

      setSelectedSegmentsIds((prev) => {
        let next = [];

        if (isSegmentSelected(id)) {
          next = prev.filter((itemId) => itemId !== id);
        } else {
          next = [...prev, id];
        }
        sendSegmentsChangedEvent(next);
        return next;
      });
    },
    [canEditSegments, getCustomValue, isSegmentSelected, sendSegmentsChangedEvent, showErrorNotification, translate],
  );

  const getSegmentName = useCallback(
    (segmentId: string) => {
      return selectedSegments.find((s) => s.id === segmentId)?.name;
    },
    [selectedSegments],
  );

  return (
    <SegmentsContext.Provider
      value={{
        segments: segments ?? [],
        isLoading,
        selectedSegments,
        isSegmentSelected,
        toggleSegmentSelection,
        isExpanded: isMediumDevice ? false : isExpanded,
        setIsExpanded,
        getSegmentName,
      }}
    >
      {children}
    </SegmentsContext.Provider>
  );
};

function useSegmentsContext() {
  return useContext(SegmentsContext) as SegmentsContextType;
}

export { SegmentsContextProvider, useSegmentsContext };
