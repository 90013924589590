import { platform } from '@va/constants';
import { RequireAppReinstallModal } from '@va/dashboard/components';
import {
  getWebsitePlatform,
  hadRecordings as hadRecordingsSelector,
  hasSsrCodeInstalled,
  hideSSrWarningSign,
  isFreeWixWebsite,
  isWebsiteInProgress,
  wasInstalledAfterSsrV0,
} from '@va/dashboard/selectors/core';
import { isSidebarOpen, isTabletDevice } from '@va/dashboard/selectors/ui';
import { navigationActions } from '@va/dashboard/slices';
import { ArrowRightIcon, TrackingCodeIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasTrackingCodeError } from '@va/standalone/shared/selectors';
import { IconWithBackground, Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
export const TrackingCodeButton: React.FC<{ className?: string }> = ({ className }) => {
  const websitePlatform = useSelector(getWebsitePlatform);
  const isWixWebsite = websitePlatform === platform.WIX;
  const ssrCodeInstalled = useSelector(hasSsrCodeInstalled);
  const hideSSrWarning = useSelector(hideSSrWarningSign);
  const hadRecordings = useSelector(hadRecordingsSelector);
  const installedAfterSsrV0 = useSelector(wasInstalledAfterSsrV0);
  const websiteInProgress = useSelector(isWebsiteInProgress);
  const isFreeWix = useSelector(isFreeWixWebsite);
  const hasTrackingCodeErr = useSelector(hasTrackingCodeError);

  if (websiteInProgress) return null;
  if (platform.CUSTOM === websitePlatform && !hasTrackingCodeErr) return null;
  if (ssrCodeInstalled || hideSSrWarning || hadRecordings) return null;
  if (!installedAfterSsrV0) return null;
  if (![platform.WIX, platform.CUSTOM].includes(websitePlatform)) return null; // Show only for wix or standalone custom websites

  if (isWixWebsite && !isFreeWix) {
    return <WixPremiumBtn className={className} />;
  }

  if (isWixWebsite && isFreeWix) {
    return <WixFreeBtn className={className} />;
  }

  return <DefaultBtn className={className} />;
};

const DefaultBtn = ({ className }: { className?: string }) => {
  const history = useHistory();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isTablet = useSelector(isTabletDevice);

  return (
    <BtnComponent
      tooltip={{ content: translate('button.goToTrackingCodePageTooltip'), disabled: isTablet }}
      onClick={() => {
        dispatch(navigationActions.closeSidebar());
        history.push('/settings/tracking-code');
      }}
      color='#EA2A0C'
      topText={translate('trackingCode.install')}
      bottomText={translate('trackingCode.notInstalledYet')}
      className={className}
    />
  );
};

const WixPremiumBtn = ({ className }: { className?: string }) => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <BtnComponent
      onClick={() => {
        dispatch(navigationActions.closeSidebar());
        history.push('/settings/tracking-code');
      }}
      color='#A50FEC'
      topText={translate('wix.trackingCodeBtn.premium.topText')}
      bottomText={translate('wix.trackingCodeBtn.premium.bottomText')}
      className={className}
    />
  );
};

const WixFreeBtn = ({ className }: { className?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const translate = useTranslate();

  return (
    <>
      <RequireAppReinstallModal
        close={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
      />
      <BtnComponent
        onClick={() => {
          setIsModalOpen(true);
        }}
        color='#EA2A0C'
        topText={translate('wix.trackingCodeBtn.free.topText')}
        bottomText={translate('wix.trackingCodeBtn.free.bottomText')}
        className={className}
      />
    </>
  );
};

const BtnComponent = ({
  topText,
  bottomText,
  color,
  onClick,
  tooltip,
  className,
}: {
  topText: string;
  bottomText: string;
  color: string;
  onClick: () => void;
  tooltip?: {
    content?: string;
    disabled?: boolean;
  };
  className?: string;
}) => {
  const isSidebarExpanded = useSelector(isSidebarOpen);

  return (
    <TooltipWrapper
      disabled={!isNil(tooltip?.disabled) ? tooltip?.disabled : isSidebarExpanded}
      content={
        tooltip?.content ? (
          tooltip.content
        ) : (
          <div>
            <Paragraph>{topText}</Paragraph>
            <Paragraph>{bottomText}</Paragraph>
          </div>
        )
      }
    >
      <button
        onClick={onClick}
        style={{ backgroundColor: color }}
        className={classNames(
          'w-full h-60px rounded-15 flex items-center gap-15px overflow-hidden justify-start text-left hover:opacity-90',
          { 'px-6px': !isSidebarExpanded, 'px-9px': isSidebarExpanded },
          className,
        )}
      >
        <IconWithBackground className='bg-white-snow shrink-0' icon={() => <TrackingCodeIcon color={color} />} />
        <div className={classNames({ 'opacity-0': !isSidebarExpanded }, 'overflow-hidden')}>
          <ParagraphWithTooltip colorClassName='text-white' size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {topText}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip colorClassName='text-white' size={paragraphSizes.normal} weight={fontWeights.medium}>
            {bottomText}
          </ParagraphWithTooltip>
        </div>
        <ArrowRightIcon className='ml-auto' color='#FFFFFF80' />
      </button>
    </TooltipWrapper>
  );
};
