import { Z_INDEX } from '@va/constants';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { PropsWithChildren, useRef } from 'react';
import { createPortal } from 'react-dom';

type ModalWrapperPropTypes = {
  closeModal: () => void;
  isModalOpen: boolean;
  contentWrapperClassName?: string;
  className?: string;
  contentClassName?: string;
  closeOnBackdropClick?: boolean;
};
const ModalWrapper: React.FC<PropsWithChildren<ModalWrapperPropTypes>> = ({
  children,
  closeModal,
  isModalOpen,
  className,
  contentClassName,
  closeOnBackdropClick = false,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowDimensions();

  if (!isModalOpen) return null;

  return createPortal(
    <div
      onClick={(e) => {
        if (modalContentRef.current && !modalContentRef.current.contains(e.target as any) && closeOnBackdropClick) {
          closeModal();
        }
      }}
      className={classNames(
        'z-[var(--zIndex-modal)] fixed flex text-white items-center justify-center transition-all duration-1000 bg-black-lighter-50 top-0 left-0 w-full h-screen px-3',
        !isModalOpen ? 'hidden' : '',
        className,
      )}
      style={{ zIndex: Z_INDEX.modals }}
    >
      <div
        ref={modalContentRef}
        className={classNames(
          'bg-white rounded-24 text-gray-charcoal overflow-hidden',
          {
            'absolute mx-3 top-12': isMobile,
            'static mx-0': !isMobile,
          },
          contentClassName,
        )}
      >
        <div
          className='overflow-auto scrollbar scrollbar-thumb'
          style={{
            maxHeight: '90svh',
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.querySelector('#root')!,
  );
};
export { ModalWrapper };
