import classNames from 'classnames';
import React from 'react';
import { FallbackIconCircle } from '../misc/FallbackIcons';
import { BlackBerryLogo } from '../operating-systems-icons';
import { AmazonSilkIcon } from './AmazonSilkIcon';
import { AndroidWebviewIcon } from './AndroidWebViewIcon';
import { ChromeIcon } from './ChromeIcon';
import { EdgeIcon } from './EdgeIcon';
import { FacebookAppIcon } from './FacebookAppIcon';
import { FacebookMessengerIcon } from './FacebookMessengerIcon';
import { GoogleAppIcon } from './GoogleAppIcon';
import { InstagramIcon } from './InstagramIcon';
import { MozillaIcon } from './MozillaIcon';
import { OperaIcon } from './OperaIcon';
import { SafariIcon } from './SafariIcon';
import { SamsungInternetIcon } from './SamsungInternetIcon';
import { TwitterAppIcon } from './TwitterAppIcon';

export const BrowserIcon: React.FC<{ browserName: string | undefined; className?: string; monochrome?: boolean }> = ({
  browserName,
  monochrome,
  className,
}) => {
  const classes = classNames(className, 'ignore-dark-mode');

  switch (browserName?.toLowerCase().trim()) {
    case 'chrome':
    case 'chromium':
    case 'chromeos':
      return <ChromeIcon className={classes} monochrome={monochrome} />;
    case 'mozilla':
    case 'firefox':
    case 'firefox for ios':
    case 'firefox focus':
      return <MozillaIcon className={classes} monochrome={monochrome} />;
    case 'safari':
    case 'mobile safari uiwebview':
      return <SafariIcon className={classes} monochrome={monochrome} />;
    case 'opera':
    case 'opera mini':
    case 'opera neon':
    case 'opera mobile':
      return <OperaIcon className={classes} monochrome={monochrome} />;
    case 'edge':
    case 'edge mobile':
    case 'ie':
    case 'internet explorer':
    case 'iemobile':
    case 'microsoft edge':
      return <EdgeIcon className={classes} monochrome={monochrome} />;
    case 'android':
    case 'android webview':
      return <AndroidWebviewIcon className={classes} />;
    case 'facebook messenger':
      return <FacebookMessengerIcon className={classes} />;
    case 'facebook app':
      return <FacebookAppIcon className={classes} monochrome={monochrome} />;
    case 'twitter app':
      return <TwitterAppIcon className={classes} monochrome={monochrome} />;
    case 'instagram app':
    case 'instagram':
      return <InstagramIcon className={classes} monochrome={monochrome} />;
    case 'google app':
    case 'google+ app':
      return <GoogleAppIcon className={classes} />;
    case 'google bot':
      return <ChromeIcon className={classes} monochrome={monochrome} />;
    case 'blackberry':
    case 'blackberry playbook tablet':
      return <BlackBerryLogo className={classes} />;
    case 'samsung internet for android':
    case 'samsung browser':
    case 'samsung webview':
    case 'samsung':
      return <SamsungInternetIcon className={classes} monochrome={monochrome} />;
    case 'silk':
      return <AmazonSilkIcon className={classes} />;
    default:
      return <FallbackIconCircle className={classes} />;
  }
};
