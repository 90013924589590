import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { useCustomizationContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';

export const FilterActionButtons = ({
  onClose,
  onSubmit,
  submitButtonDisabled,
  wrapperClassName,
}: {
  onClose?: () => void;
  onSubmit?: () => void;
  submitButtonDisabled?: boolean;
  wrapperClassName?: string;
}) => {
  const translate = useTranslate();

  const { canEditFilters } = usePermissionsProvider();
  const { getCustomValue } = useCustomizationContext();

  return (
    <div className={classNames('flex gap-2 w-full mt-1', wrapperClassName)}>
      {onClose && (
        <Button
          data-testid={TEST_IDS.generic.buttons.close}
          color='tertiary'
          text={translate('button.close')}
          className='w-full p-2 py-9px rounded-lg'
          textClasses='text-12 leading-18'
          onClick={onClose}
        />
      )}
      {onSubmit && (
        <Button
          data-testid={TEST_IDS.generic.buttons.submit}
          color='primary'
          tooltip={
            !canEditFilters &&
            getCustomValue('disabledFiltersMessage', translate('all.defaultWarnings.cantEditFilters'))
          }
          disabled={submitButtonDisabled || !canEditFilters}
          text={translate('button.submit')}
          className='w-full p-2 py-9px rounded-lg'
          textClasses='text-12 leading-18'
          onClick={onSubmit}
        />
      )}
    </div>
  );
};
