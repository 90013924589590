import { SortingState } from '@tanstack/react-table';
import {
  AlarmingBehaviorResponse,
  alarmingEventsTranslations,
  AlarmingEventTrigger,
  Group,
  Order,
} from '@va/types/events';
import { Filter } from '@va/types/filters';
import { Direction } from '@va/types/table';
import { CHART_COLORS_ARRAY } from '@va/ui/design-system';
import { buildApiConditions } from '@va/util/helpers';
import { AllowedOrders } from '../data/order';

export function mapAlarmingBehaviorResponse(response: AlarmingBehaviorResponse): AlarmingBehaviorResponse {
  return {
    ...response,
    payload: response.payload.map((behavior, index) => ({
      ...behavior,
      color: CHART_COLORS_ARRAY[index],
      tooltipValue: isAlarmingEventTrigger(behavior.trigger)
        ? alarmingEventsTranslations[behavior.trigger]
        : behavior.page,
    })),
  };
}

export function sortByMapper(sortBy: SortingState | undefined): Order[] | undefined {
  if (!sortBy || sortBy.length === 0) return undefined;

  return sortBy
    .filter(({ id }) => AllowedOrders.includes(id))
    .map(({ id, desc }) => ({ member: id, direction: desc ? Direction.DESC : Direction.ASC }));
}

function isAlarmingEventTrigger(value: string): boolean {
  const validAlarmingEvents = Object.keys(AlarmingEventTrigger);
  return validAlarmingEvents.includes(value);
}

export const formatApiParameters = (
  appliedFilters: Filter<any, Record<string, unknown>>[],
  initialFilters: Filter<any, Record<string, unknown>>[] | undefined,
  group: Group,
  pagination: {
    pageNumber: number;
    pageIndex: number;
    pageSize: number;
  },
  order: Order[] | undefined,
) => {
  const allFilters = initialFilters ? [...appliedFilters, ...initialFilters] : appliedFilters;
  const where = { and: buildApiConditions(allFilters) };

  const orderFormatted = order?.at(0) ? `${order[0].member}.${order[0].direction}` : undefined;

  return {
    query: {
      group,
      order: orderFormatted,
    },
    body: {
      where,
      pagination: {
        page: pagination.pageNumber,
        pageSize: pagination.pageSize,
      },
    },
  };
};
