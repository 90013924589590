import { CheckmarkIcon, LockIcon, NavVisitorSegments, SquareIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { IconWithBackground, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { useIsHovering } from '@va/util/hooks';
import { useMemo, useRef } from 'react';
import { useVisitorSegmentsContext } from '../VisitorSegmentsContext';

const SegmentTypeCell = ({ name, isDefaultSegment, id }: { name: string; isDefaultSegment: boolean; id: string }) => {
  const { toggleSegmentSelection, isSegmentSelected } = useVisitorSegmentsContext();
  const translate = useTranslate();
  const iconRef = useRef<HTMLDivElement>(null);
  const isHoveringOnIcon = useIsHovering(iconRef);

  const icon = useMemo(() => {
    if (isDefaultSegment) {
      return <LockIcon color='#3C3C3C' />;
    }
    if (isSegmentSelected(id)) {
      return <CheckmarkIcon />;
    }
    if (isHoveringOnIcon) {
      return <SquareIcon />;
    }
    return <NavVisitorSegments />;
  }, [id, isDefaultSegment, isHoveringOnIcon, isSegmentSelected]);

  return (
    <div className='flex gap-2 justify-start items-center'>
      <IconWithBackground
        onClick={() => toggleSegmentSelection(id)}
        ref={iconRef}
        icon={() => icon}
        className='bg-gray-concrete hover:bg-white cursor-pointer group-hover:bg-white'
      />
      <div className='flex flex-col items-start'>
        <ParagraphWithTooltip weight={fontWeights.medium} className='text-gray-charcoal max-w-180'>
          {name}
        </ParagraphWithTooltip>
        {isDefaultSegment && (
          <div className='flex gap-1 items-center'>
            <LockIcon className='h-3 w-3' color='#696969' />
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              className='text-gray-devilSolid'
            >
              {translate('labels.defaultSegment')}
            </ParagraphWithTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default SegmentTypeCell;
