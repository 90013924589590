import { ArrowLeft } from '@va/icons';
import { ReportBlockNodeItem } from '@va/types/report-block';
import { fontWeights, MenuListItem, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

export const ReportBlockNodesMenu = ({
  nodes,
  selectedNode,
  setSelectedNode,
}: {
  nodes: ReportBlockNodeItem[];
  selectedNode: ReportBlockNodeItem | null;
  setSelectedNode: Dispatch<SetStateAction<ReportBlockNodeItem | null>>;
}) => {
  const { context, isPositioned } = useTooltipContext();
  const closeTooltip = useCallback(() => context.onOpenChange(false), [context]);

  useEffect(() => {
    if (!context.open && selectedNode) setSelectedNode(null);
  }, [context.open, selectedNode, setSelectedNode, isPositioned]);

  if (selectedNode?.menuComponent) {
    return (
      <>
        <div
          className='flex items-center gap-4 p-9px cursor-pointer hover:bg-white-snow rounded-md mb-1'
          onClick={() => setSelectedNode(null)}
        >
          <ArrowLeft className='h-2.5' />
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {selectedNode.label}
          </Paragraph>
        </div>
        {selectedNode.menuComponent?.(closeTooltip)}
      </>
    );
  }

  return (
    <>
      {nodes.map((node) => (
        <TooltipWrapper key={node.id} content={node.tooltip} disabled={!node.tooltip} {...node.tooltipProps}>
          <MenuListItem
            label={node.label}
            icon={node.icon}
            onClick={() => node.onClick?.() ?? setSelectedNode(node)}
            endAdornment={node.endAdornment}
            disabled={node.disabled}
          />
        </TooltipWrapper>
      ))}
    </>
  );
};
