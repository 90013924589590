import { OnChangeFn, PaginationState, SortingState } from '@tanstack/react-table';
import { useReportBlockNodeBuilder } from '@va/dashboard/shared/report-block-nodes';
import { useTranslate } from '@va/localization';
import { AppliedFilters } from '@va/shared/feature-filters';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { ReportBlock } from '@va/ui/components/report-block';
import { ReportBlockNodeIds } from '@va/ui/components/report-block/nodes';
import { useCallback } from 'react';
import useAgencyWhiteLabelContext from '../AgencyWhiteLabelContext';
import { OrderType, WebsiteType } from '../agency-white-label-apiClient';
import { useManageWhiteLabelColumns } from './useManageWhiteLabelColumns';

const MANAGE_WL_RB = 'manage-wl';

const ManageWhiteLabelTable = () => {
  const translate = useTranslate();
  const buildReportBlockNode = useReportBlockNodeBuilder();
  const { isLoading, websites, websiteFilterQuery, setWebsiteFilterQuery, totalWebsitesCount } =
    useAgencyWhiteLabelContext();

  const { pagination, setPagination, sorting, setSorting } = useControlledTableState({
    pagination: { pageSize: websiteFilterQuery.length },
  });

  const handlePaginationChange = useCallback<OnChangeFn<PaginationState>>(
    (updater) => {
      if (typeof updater !== 'function') return;

      const updatedPagination = updater(pagination);
      setPagination(updatedPagination);
      setWebsiteFilterQuery((prevFilters) => ({
        ...prevFilters,
        start: (updatedPagination?.pageIndex ?? 0) * (updatedPagination?.pageSize ?? 0),
        length: updatedPagination?.pageSize ?? 10,
      }));
    },
    [pagination, setPagination, setWebsiteFilterQuery],
  );

  const handleSortingChange = useCallback<OnChangeFn<SortingState>>(
    (updater) => {
      if (typeof updater !== 'function') return;
      const updatedSorting = updater(sorting);
      setSorting(updatedSorting);
      setWebsiteFilterQuery((prevFilters) => ({
        ...prevFilters,
        order: updatedSorting?.[0]?.desc ? OrderType.DESC : OrderType.ASC,
      }));
    },
    [setSorting, setWebsiteFilterQuery, sorting],
  );

  const columns = useManageWhiteLabelColumns();

  return (
    <ReportBlock
      id={MANAGE_WL_RB}
      title={translate('standalone.accountSettings.whiteLabelWebsites.title')}
      nodes={{
        filterButton: buildReportBlockNode(ReportBlockNodeIds.FILTER_BUTTON),
        appliedFilters: <AppliedFilters />,
        pagination: <div id={MANAGE_WL_RB} />,
      }}
      visualization={{ selectedView: DataViewOption.table }}
      visualizationComponents={{
        [DataViewOption.table]: () => (
          <DataTableV8<WebsiteType>
            id='manage-wl-table'
            isLoading={isLoading}
            state={{ pagination, sorting }}
            paginationContainerId={MANAGE_WL_RB}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            rowCount={totalWebsitesCount}
            columns={columns}
            data={websites}
            minWidth={1000}
          />
        ),
      }}
    />
  );
};

export default ManageWhiteLabelTable;
