import { apiGateway } from '@va/api-client';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CreateSegmentPayload, UpdateSegmentPayload, VisitorSegment, VisitorSegmentsResponse } from './types';

export const useGetVisitorSegments = (tableQuery?: { order: string | undefined }) => {
  const websiteId = useSelector(getInstanceId);
  const key = `get-segments${toQueryString(tableQuery)}`;
  const mapper = useCallback((response: VisitorSegmentsResponse) => {
    if (!response?.data) return [];
    // Added to remove default segments. Not part of this iteration
    return response.data.filter((segment) => segment.id !== 'default');
  }, []);
  const fetcher = useCallback(() => getVisitorSegments(websiteId, tableQuery), [tableQuery, websiteId]);

  return useFetchData<VisitorSegment[]>(key, undefined, mapper, fetcher);
};

export const useCreateVisitorSegments = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = useCallback((data: CreateSegmentPayload) => createVisitorSegment(websiteId, data), [websiteId]);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useUpdateVisitorSegments = () => {
  const asyncFunc = useCallback((segmentId: string, data: UpdateSegmentPayload) => {
    return updateVisitorSegment(segmentId, data);
  }, []);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useDeleteVisitorSegment = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = useCallback((segmentIds: string[]) => deleteVisitorSegment(websiteId, segmentIds), [websiteId]);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const getVisitorSegments = (websiteId: string, tableQuery?: { order: string | undefined }) => {
  const stringifiedQuery = tableQuery ? toQueryString(tableQuery) : '';
  const url = `/v1/websites/${websiteId}/segments?${stringifiedQuery}`;
  return apiGateway.get(url);
};

export const createVisitorSegment = async (websiteId: string, data: {}) => {
  const response = await apiGateway.post(`/v1/websites/${websiteId}/segments`, data);
  return response.data;
};

export const updateVisitorSegment = async (segmentId: string, data: {}) => {
  const response = await apiGateway.patch(`/v1/segments/${segmentId}`, data);
  return response.data;
};

export const deleteVisitorSegment = async (websiteId: string, segmentIds: string[]) => {
  const response = apiGateway.delete<{ data: { deletedSegments: string[]; notDeletedSegments: string[] } }>(
    `/v1/websites/${websiteId}/segments`,
    {
      data: { ids: segmentIds },
    },
  );
  return response;
};
