import { tabNames, tabsWithFeatureLimit } from '@va/constants';
import { hasUnlimitedVoucher as hasUnlimitedVoucherCode } from '@va/dashboard/selectors/app';
import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { Checkmark } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { RECORDINGS_COUNT_PRO_PLUS_PLAN } from '@va/standalone/shared/constants';
import { getNavIconByName } from '@va/standalone/shared/helpers';
import { ButtonLoader, StarV2 } from '@va/svg-visa-icons';
import { CircularProgress, fontWeights, Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';
import { addNumberSeparator, isWixApp } from '@va/util/helpers';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './FeaturesCounterTooltip.scss';
import PlanNameWithUpgradeBtn from './PlanNameWithUpgradeBtn';

const FeaturesCounterTooltip: React.FC<{}> = () => {
  const featureCardsInfo: any = useFeatureCounterContext();
  const hasUnlimitedVoucher: boolean = useSelector(hasUnlimitedVoucherCode);
  const { context } = useTooltipContext();

  if (featureCardsInfo?.events) {
    featureCardsInfo.eventTracking = featureCardsInfo?.events;
  }

  const translate = useTranslate();
  const hasPremiumSupport = featureCardsInfo?.recordings?.limit > RECORDINGS_COUNT_PRO_PLUS_PLAN || false; //We have premium support for pro++ and greater plans.

  const getFeatureStatusLabel = useCallback(
    (tab: string) => {
      if (tab === tabNames.eventTracking && isWixApp()) {
        if (hasUnlimitedVoucher) return translate(`featureCounter.eventTrackingWix.limitConsumed`);
        return translate(`featureCounter.eventTrackingWix.limitLeft`);
      }

      if (hasUnlimitedVoucher) return translate(`featureCounter.${tab}.limitConsumed`);
      return translate(`featureCounter.${tab}.limitLeft`);
    },
    [hasUnlimitedVoucher, translate],
  );

  return (
    <div className='feature-counter-tooltip-wrapper bg-black rounded-3xl p-3 text-white'>
      <PlanNameWithUpgradeBtn onClose={() => context.onOpenChange(false)} />
      <div className={'feature-status-section overflow-y-auto scrollbar scrollbar-thumb px-[15px]'}>
        {tabsWithFeatureLimit.map((tab: string, index: number) => {
          const tabValue = featureCardsInfo?.[tab];
          return (
            <FeatureStatus
              key={index}
              tabName={tab}
              label={getFeatureStatusLabel(tab)}
              featureLimit={tabValue?.limit ?? 0}
              consumedFeatureLimit={tabValue?.count}
            />
          );
        })}
      </div>
      <div className='flex items-center justify-between min-h-[60px] bg-black-dark/50 rounded-[15px]'>
        <div className='flex items-center ml-[15px]'>
          <StarV2 color='#FFFFF' stroke='#FFFFFF' />
          <Paragraph className='ml-[9px] opacity-90' weight={fontWeights.medium}>
            {hasPremiumSupport
              ? translate('featureCounter.premiumSupport')
              : translate('featureCounter.customerSupport')}
          </Paragraph>
        </div>
        <div className='ml-3 mr-[18px]'>
          <Checkmark color='#FFFFFF' />
        </div>
      </div>
    </div>
  );
};

export default FeaturesCounterTooltip;

type FeatureStatusProps = {
  tabName: string;
  featureLimit: number;
  consumedFeatureLimit: number;
  label: string;
};

const FeatureStatus: React.FC<FeatureStatusProps> = memo((props) => {
  const { tabName, featureLimit = 0, label, consumedFeatureLimit = 0 } = props;
  const { locale } = useLocale();
  const translate = useTranslate();
  const isMobile = useSelector(isMobileDevice);
  const hasUnlimitedVoucher: boolean = useSelector(hasUnlimitedVoucherCode);

  const countLeft = useMemo(() => featureLimit - consumedFeatureLimit, [consumedFeatureLimit, featureLimit]);

  return (
    <div className='flex items-center justify-between min-h-[30px] my-[18px]'>
      <div className='flex items-center w-full'>
        <div className='max-w-14'>{getNavIconByName(tabName, false, '#FFFFFF')}</div>
        <div
          className={classNames('flex sm:flex-col flex-row w-full justify-between sm:flex-wrap flex-nowrap', {
            truncate: isMobile,
          })}
        >
          <ParagraphWithTooltip
            className={classNames('ml-[9px] opacity-90 sm:max-w-full max-w-[60%]', {
              truncate: isMobile,
            })}
            weight={fontWeights.medium}
          >
            {label}:
          </ParagraphWithTooltip>
          {featureLimit > 0 ? (
            <Paragraph
              className={classNames('mr-3 ml-3 sm:text-left text-right', {
                'text-orange-dark': hasUnlimitedVoucher,
              })}
              weight={fontWeights.medium}
            >
              {hasUnlimitedVoucher
                ? translate('feature.counter.counting', {
                    behaviourCount: addNumberSeparator(consumedFeatureLimit, locale),
                    featureLimit: '∞',
                  })
                : translate('feature.counter.counting', {
                    behaviourCount: addNumberSeparator(countLeft < 0 ? 0 : countLeft, locale),
                    featureLimit: addNumberSeparator(featureLimit, locale),
                  })}
            </Paragraph>
          ) : (
            <ButtonLoader color='#F66F1E' />
          )}
        </div>
        {!hasUnlimitedVoucher && (
          <div className='ml-1'>
            <CircularProgress
              radius={15}
              stroke={4}
              progress={(consumedFeatureLimit / featureLimit) * 100}
              gradientColor={'#F66F1E'}
            />
          </div>
        )}
      </div>
    </div>
  );
});
