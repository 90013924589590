export const TOP_BAR_HEIGHT = 70;
export const STICKY_HEADER_HEIGHT = 400;

export const TABLET_BREAKPOINT = 1024;
export const MOBILE_BREAKPOINT = 640;
export const MEDIUM_DEVICE_BREAKPOINT = 768;

// TODO Change when https://bitbucket.org/visitoranalytics/multiapp-dashboard/pull-requests/3548 is merged
// TODO extract all zIndex value from code and add them here
export const Z_INDEX = {
  featureTour: 99999,
  modals: 2000,
  sidebar: 999,
  topBar: 999,
};

export const FEATURE_TOUR_IDS = {
  sidebarContainer: 'sidebar-container',
  goToWebsiteBtn: 'go-to-website-btn',
  userMenu: 'user-menu-btn',
  addWebsiteBtn: 'add-website-btn',
  manageWebsitesBtn: 'manage-websites-btn',
  websiteSelectorBtn: 'website-selector-btn',
  openTourContainerButton: 'feature-tour-button-container',
};

/** @deprecated use import from @va/types/report-block */
export enum DataViewOption {
  lineDiagram = 'lineDiagram',
  table = 'table',
  barChart = 'barChart',
  stackedLineDiagram = 'stackedLineDiagram',
  steppedLineDiagram = 'steppedLineDiagram',
  radarDiagram = 'radarDiagram',
  horizontalBarChart = 'horizontalBarChart',
  pieChart = 'pieChart',
  kpi = 'kpi',
  map = 'map',
  card = 'card',
}
