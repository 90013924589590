import { Filter } from '@va/types/filters';
import { useSubmitOnEnter } from '@va/util/hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useFiltersContext } from '../filters-context';
import { applyFilterValues } from '../table-filters-helpers';
import { FilterActionButtons } from './FilterActionButtons';
import { SubFilterTabHeader } from './SubFilterTabHeader';

export const IndividualFilter = ({
  filter,
  onBackClick,
  onClose,
}: {
  filter: Filter;
  onBackClick: () => void;
  onClose: () => void;
}) => {
  const { expandFilters, isFilterApplied } = useFiltersContext();
  const inputRef = useRef<{ submit: () => void }>(null);

  const appliedFilter = useMemo(() => isFilterApplied(filter.id), [filter.id, isFilterApplied]);
  const updatedFilter = useMemo(() => {
    return appliedFilter ? applyFilterValues(filter, appliedFilter) : filter;
  }, [appliedFilter, filter]);

  const Input = filter.input;

  const handleSubmit = useCallback(() => {
    try {
      inputRef.current?.submit();
      expandFilters();
      onClose();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [onClose, expandFilters]);

  useSubmitOnEnter(handleSubmit);

  return (
    <div>
      <SubFilterTabHeader title={filter.label} onBackClick={onBackClick} />
      <Input ref={inputRef} filter={updatedFilter} {...filter?.inputProps} />
      <FilterActionButtons onClose={onClose} onSubmit={handleSubmit} />
    </div>
  );
};
