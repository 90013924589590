import { getAccountInformation } from '@va/dashboard/selectors/api';
import { getWebsite, isWixWebsite } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { ContributorsMappedDataType } from '@va/types/contributors';
import { getFullNameFromUserInfo } from '@va/util/helpers';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';

export const useFullNameById = (
  userId: string | null | undefined,
  websiteContributors: ContributorsMappedDataType | undefined,
) => {
  const translate = useTranslate();
  const SOMEONE_ELSE = translate('labels.unknown');
  const accountInformation = useSelector(getAccountInformation);
  const isWix = useSelector(isWixWebsite);
  const website = useSelector(getWebsite);

  if (isWix || !Object.keys(accountInformation)?.length) {
    return getFullNameFromUserInfo(website);
  }

  if (!websiteContributors) return null;

  if (!userId) {
    return startCase(websiteContributors?.owner?.fullName ?? '') ?? SOMEONE_ELSE;
  }

  if (userId === accountInformation.id) {
    return getFullNameFromUserInfo(accountInformation);
  }

  const foundCreator = findCreatorInContributors(userId, websiteContributors);
  if (foundCreator) {
    return foundCreator.fullName ? startCase(foundCreator.fullName) : foundCreator.email;
  }

  return SOMEONE_ELSE;
};

function findCreatorInContributors(userId: string, contributorsResponse: ContributorsMappedDataType) {
  if (!contributorsResponse) return null;
  const { owner, contributors } = contributorsResponse;

  if (owner?.id === userId) {
    return owner;
  }

  return contributors.find((item) => item.id === userId);
}
