import { PropsWithChildren, RefObject, createContext, useContext, useEffect, useRef, useState } from 'react';

type CtxValues = {
  isIntersecting: boolean | undefined;
  setIsIntersecting: React.Dispatch<React.SetStateAction<boolean>>;
  defaultOptions?: IntersectionObserverInit;
};

type IntersectionObserverProviderProps = {
  defaultOptions?: IntersectionObserverInit;
};

const IntersectionObserverCtx = createContext<CtxValues>({ isIntersecting: undefined, setIsIntersecting: () => {} });

/**
 * @example
 * 1. Wrap the component inside this low level provider:
 *    <IntersectionObserverProvider>
 *      <Component />
 *    </IntersectionObserverProvider>
 * 2. Setup the observer inside the component:
 *    const elementRef = useRef()
 *    useStartIntersectionObserverCtx(elementRef)
 * 3. Access the isIntersecting state from anywhere inside the component tree without prop drilling
 */
export const IntersectionObserverProvider = ({
  children,
  defaultOptions,
}: PropsWithChildren<IntersectionObserverProviderProps>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  return (
    <IntersectionObserverCtx.Provider value={{ isIntersecting, setIsIntersecting, defaultOptions }}>
      {children}
    </IntersectionObserverCtx.Provider>
  );
};

export const useIntersectionObserverCtx = () => useContext(IntersectionObserverCtx);

export const useStartIntersectionObserverCtx = (elRef: RefObject<Element>, callback?: IntersectionObserverCallback) => {
  const { defaultOptions, setIsIntersecting } = useIntersectionObserverCtx();

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!elRef.current || observer.current) return;

    observer.current = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      setIsIntersecting(entry.isIntersecting);
      callback?.(entries, observer);
    }, defaultOptions);

    const obs = observer.current;
    obs.observe(elRef.current);

    return () => {
      obs.disconnect();
      observer.current = null;
    };
  }, [callback, defaultOptions, elRef, setIsIntersecting]);
};
