export const CalendarIcon = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.5 5.25C1.5 3.59315 2.84315 2.25 4.5 2.25H13.5C15.1569 2.25 16.5 3.59315 16.5 5.25V6H1.5V5.25Z'
        opacity='0.15'
        fill={color}
      />
      <path
        d='M1.5 5.25C1.5 3.59315 2.84315 2.25 4.5 2.25H13.5C15.1569 2.25 16.5 3.59315 16.5 5.25V12.75C16.5 14.4069 15.1569 15.75 13.5 15.75H4.5C2.84315 15.75 1.5 14.4069 1.5 12.75V5.25Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path d='M6 0.75V3.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M12 0.75V3.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <circle cx='4.5' cy='12.75' r='1.5' fill={color} opacity='0.15' />
      <circle cx='4.5' cy='8.25' r='1.5' fill={color} opacity='0.15' />
      <circle cx='9' cy='12.75' r='1.5' fill={color} opacity='0.15' />
      <circle cx='9' cy='8.25' r='1.5' fill={color} opacity='0.15' />
      <circle cx='13.5' cy='12.75' r='1.5' fill={color} opacity='0.75' />
      <circle cx='13.5' cy='8.25' r='1.5' fill={color} opacity='0.15' />
    </svg>
  );
};
