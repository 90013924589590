import { getInstanceId } from '@va/dashboard/selectors/app';
import { patch, post, remove } from '@va/http-client';
import { ContributorType, ContributorsApiResponseType, ContributorsMappedDataType } from '@va/types/contributors';
import { UserRole } from '@va/types/website';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SWRConfiguration } from 'swr';

export const useGetWebsiteContributors = (config: SWRConfiguration = { revalidateOnFocus: false }, mapperFn?: any) => {
  const websiteId = useSelector(getInstanceId);

  const getSortedContributors = useCallback((contributors: ContributorType[]) => {
    return contributors.sort((a, b) => {
      const aValue = a.fullName?.toLowerCase() ?? a.email?.toLowerCase();
      const bValue = b.fullName?.toLowerCase() ?? b.email?.toLowerCase();

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
  }, []);

  const mapper = useCallback(
    (data: ContributorsApiResponseType) => {
      const { owner, contributors, contributorRequests = [] } = data;

      const mergedContributors = [] as ContributorType[];
      Object.entries(contributors).forEach(([role, contributors]) => {
        contributors.forEach((contributor) => {
          mergedContributors.push({
            ...contributor.user,
            dashboardPermissions: contributor.dashboardPermissions,
            contributorId: contributor.id,
            role,
          });
        });
      });

      const mergedContributorRequests = [] as ContributorType[];
      Object.entries(contributorRequests).forEach(([role, contributorRequests]) => {
        contributorRequests.forEach((request) => {
          mergedContributorRequests.push({ ...request, role });
        });
      });

      return {
        owner: { ...owner, role: UserRole.OWNER },
        contributors: getSortedContributors(mergedContributors),
        contributorRequests: getSortedContributors(mergedContributorRequests),
      };
    },
    [getSortedContributors],
  );

  const mapperFunc = mapperFn ?? mapper;

  return useFetchData<ContributorsMappedDataType>(`/websites/${websiteId}/contributors`, config, mapperFunc);
};

export const useAddContributor = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string; type: string }) =>
    post('/contributors/requests', {}, { ...data, websiteId });
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useDeletePendingContributor = () => {
  const asyncFunc = (contributorId: string, role: string) =>
    remove(`/contributors/requests/${contributorId}?type=${role}`);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useDeleteActiveContributor = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (contributorId: string) => remove(`/contributors/${contributorId}/websites/${websiteId}`);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useChangeContributor = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (contributorId: string, roles: string[]) =>
    patch(`/contributors/${contributorId}/websites/${websiteId}`, {}, { types: roles });
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};
