import { closeModal } from '@va/dashboard/actions/ui';
import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useEffect } from 'react';
import { useDeleteBulkRecordings, useDeleteRecordingsApi, useMutateRecordings } from './apiClient';

export enum DeleteRecordingsCallType {
  bulk = 'bulk',
  individual = 'individual',
}

export const useDeleteRecordings = (type: DeleteRecordingsCallType, onSuccess?: () => void) => {
  const {
    recordings: { mutate: refreshRecordingsCount },
  } = useFeatureCounterContext();

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const apiCall = type === DeleteRecordingsCallType.bulk ? useDeleteBulkRecordings : useDeleteRecordingsApi;

  const { execute, isSucceeded, error, clearError, isLoading } = apiCall();

  useMutateRecordings(isSucceeded);

  useEffect(() => {
    if (!isSucceeded) return;
    closeModal();
    onSuccess?.();
    showSuccessNotification();
    refreshRecordingsCount();
  }, [isSucceeded, onSuccess, refreshRecordingsCount, showSuccessNotification]);

  useEffect(() => {
    if (!error) return;
    clearError();
    showErrorNotification();
  }, [clearError, error, showErrorNotification]);

  return { execute, error, isSucceeded, isLoading };
};

export default useDeleteRecordings;
