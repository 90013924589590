import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { ReportBlockNodeIds, ReportBlockNodes } from './nodes';
import { useReportBlockCtx } from './report-block-ctx';
import { useReportBlockHeaderCtx } from './report-block-header-ctx';
import { getReportBlockPaginationId } from './report-block.helpers';
import { ReportBlockTitle } from './ReportBlockTitle';

export const ReportBlockHeader = memo(() => {
  const { nodes, id, size = 'medium', headerClassName } = useReportBlockCtx();

  const paginationId = useMemo(() => getReportBlockPaginationId(id), [id]);

  return (
    <div
      className={classNames('flex', headerClassName, {
        'flex-row items-center': !nodes?.bulkActions,
        'flex-col gap-3 sm:gap-4.5px': nodes?.bulkActions,
      })}
    >
      {nodes?.bulkActions && <ReportBlockTitle />}
      <div
        className={classNames(
          'invisible-on-screenshot flex items-center overflow-hidden flex-wrap justify-end w-full',
          {
            'gap-1.5': size === 'small',
            'gap-4.5px': size === 'medium',
          },
        )}
      >
        {!nodes?.bulkActions && <ReportBlockTitle />}
        {nodes?.bulkActions && <div>{nodes?.bulkActions}</div>}
        {nodes?.rightSideJSX}
        {nodes?.datePicker}
        <ReportBlockNodes />
        {nodes?.pagination ?? <div className='empty:hidden' id={paginationId}></div>}
        {nodes?.dataViewButton}
      </div>
    </div>
  );
});

export const ReportBlockSubHeader = ({ className }: { className?: string }) => {
  const { nodes } = useReportBlockCtx();
  const { headerNodes } = useReportBlockHeaderCtx();

  const isSegmentsButtonVisible = useMemo(
    () => !!headerNodes.find((n) => n.id === ReportBlockNodeIds.SEGMENTS_BUTTON && n.visible),
    [headerNodes],
  );
  const isFilterButtonVisible = useMemo(
    () => !!headerNodes.find((n) => n.id === ReportBlockNodeIds.FILTER_BUTTON && n.visible),
    [headerNodes],
  );

  return (
    <div className={classNames('mb-18px', className)}>
      {isSegmentsButtonVisible && nodes?.segments}
      {isFilterButtonVisible && nodes?.appliedFilters}
    </div>
  );
};
