import { useTranslate } from '@va/localization';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import {
  GrayGradientDivider,
  Paragraph,
  ParagraphWithTooltip,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import classNames from 'classnames';
import { DataPrivacyCardRadioButton } from './DataPrivacyCardRadioButton';
import { DataPrivacyLevelIcon } from './DataPrivacyLevelIcon';

type DataPrivacyCardProps = {
  onClick: (value: PrivacyLevelsEnum) => void;
  selected: boolean;
  value: PrivacyLevelsEnum;
  baseKey: string;
  variant?: 'default' | 'wix-mini';
};

const styles = {
  default: {
    card: {
      base: 'w-[300px] bg-white-snow hover:bg-gray-concrete p-3 rounded-30',
      selected: 'bg-gray-concrete',
    },
    title: { className: undefined, color: 'text-text-dark', selectedColor: '' },
    subtitle: { color: '', selectedColor: '' },
    titleContainer: 'text-center mb-2',
    radioButton: undefined,
    headerContainer: 'flex-col items-center',
    descriptionText: {
      size: undefined,
      baseColor: '',
      selectedColor: '',
    },
    detailsDivider: {
      base: undefined,
      selected: '',
    },
    detailsContainer: 'p-4 mt-1 h-3/5',
    detail: {
      base: 'bg-white py-3 px-4 last:pb-1',
      selected: 'bg-white',
    },
    detailTextPrimary: {
      base: 'text-text-primary',
      selected: 'text-text-primary',
    },
    detailTextSecondary: {
      base: 'text-gray-devil',
      selected: 'text-gray-devil',
    },
  },
  'wix-mini': {
    card: {
      base: 'w-[261px] bg-blue-solitude p-1.5 rounded-[24px]',
      selected: 'bg-white hover:bg-white',
    },
    title: {
      className: '!text-15 !leading-18 text-start',
      color: 'text-black-lighter-84',
      selectedColor: '!text-primary',
    },
    subtitle: {
      color: 'text-black-lighter-60',
      selectedColor: '!text-primary',
    },
    titleContainer: 'text-start',
    radioButton: 'w-18px h-18px',
    headerContainer: 'items-center gap-9px self-start',
    descriptionText: {
      size: paragraphSizes.tiny,
      baseColor: 'text-gray-devil',
      selectedColor: 'text-gray-charcoal',
    },
    detailsDivider: {
      base: 'bg-none bg-transparent',
      selected: 'bg-none bg-white w-full',
    },
    detailsContainer: undefined,
    detail: {
      base: 'bg-white-66 px-3 py-1.5',
      selected: '!bg-gray-athens',
    },
    detailTextPrimary: {
      base: 'text-gray-devil',
      selected: '!text-gray-mine-shaft-light',
    },
    detailTextSecondary: {
      base: 'text-gray-charcoal',
      selected: 'text-gray-charcoal',
    },
  },
};

export const DataPrivacyCard = ({ onClick, selected, value, baseKey, variant = 'default' }: DataPrivacyCardProps) => {
  const translate = useTranslate();

  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className={classNames(
        'relative flex flex-col items-center cursor-pointer text-center break-words shrink-0 gap-1',
        styles[variant].card.base,
        { [styles[variant].card.selected]: selected },
      )}
    >
      <DataPrivacyCardRadioButton selected={selected} variant={variant} />
      <div className={classNames('flex w-full', styles[variant].headerContainer)}>
        <DataPrivacyLevelIcon selected={selected} value={value} variant={variant} />
        <div className={styles[variant].titleContainer}>
          <Paragraph
            className={styles[variant].title.className}
            size={paragraphSizes.large}
            colorClassName={classNames({
              [styles[variant].title.color]: !selected,
              [styles[variant].title.selectedColor]: selected,
            })}
            weight={fontWeights.semibold}
          >
            {translate(`${baseKey}.title`)}
          </Paragraph>
          <Paragraph
            size={paragraphSizes.tiny}
            weight={fontWeights.medium}
            colorClassName={classNames({
              [styles[variant].subtitle.color]: !selected,
              [styles[variant].subtitle.selectedColor]: selected,
            })}
          >
            {translate(`${baseKey}.subtitle`)}
          </Paragraph>
        </div>
      </div>
      <div className='rounded-18 text-text-primary w-full break-words overflow-hidden'>
        <div className={classNames(styles[variant].detail.base, { [styles[variant].detail.selected]: selected })}>
          <Paragraph
            size={styles[variant].descriptionText.size}
            colorClassName={classNames({
              [styles[variant].descriptionText.baseColor]: !selected,
              [styles[variant].descriptionText.selectedColor]: selected,
            })}
            weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
          >
            {translate(`${baseKey}.info1`)}
          </Paragraph>
        </div>
        <GrayGradientDivider
          className={classNames(styles[variant].detailsDivider.base, {
            [styles[variant].detailsDivider.selected]: selected,
          })}
        />

        <div className={classNames(styles[variant].detail.base, { [styles[variant].detail.selected]: selected })}>
          <Paragraph
            size={paragraphSizes.tiny}
            colorClassName={classNames(styles[variant].detailTextPrimary.base, {
              [styles[variant].detailTextPrimary.selected]: selected,
            })}
            weight={fontWeights.medium}
          >
            {translate(`${baseKey}.info2`)}
          </Paragraph>
        </div>
        <GrayGradientDivider
          className={classNames(styles[variant].detailsDivider.base, {
            [styles[variant].detailsDivider.selected]: selected,
          })}
        />

        <div className={classNames(styles[variant].detail.base, { [styles[variant].detail.selected]: selected })}>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            colorClassName={classNames(styles[variant].detailTextSecondary.base, {
              [styles[variant].detailTextSecondary.selected]: selected,
            })}
            weight={fontWeights.medium}
          >
            {translate('onboarding.privacyLevels.modeComplies')}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
            colorClassName={classNames(styles[variant].detailTextPrimary.base, {
              [styles[variant].detailTextPrimary.selected]: selected,
            })}
          >
            {translate(`${baseKey}.info3`)}
          </ParagraphWithTooltip>
        </div>
        <GrayGradientDivider
          className={classNames(styles[variant].detailsDivider.base, {
            [styles[variant].detailsDivider.selected]: selected,
          })}
        />

        <div className={classNames(styles[variant].detail.base, { [styles[variant].detail.selected]: selected })}>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            colorClassName={classNames(styles[variant].detailTextSecondary.base, {
              [styles[variant].detailTextSecondary.selected]: selected,
            })}
            weight={fontWeights.medium}
          >
            {translate('onboarding.privacyLevels.dataTracking')}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
            colorClassName={classNames(styles[variant].detailTextPrimary.base, {
              [styles[variant].detailTextPrimary.selected]: selected,
            })}
          >
            {translate(`${baseKey}.info4`)}
          </ParagraphWithTooltip>
        </div>
      </div>
    </div>
  );
};
