import { ArrowRightIcon } from '@va/icons';
import { TestAttributes } from '@va/types/component';
import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { fontWeights, Paragraph, paragraphSizes } from '../typography';

type MenuListItemProps = {
  onClick?: () => void;
  className?: string;
  icon?: ReactNode;
  label: string;
  endAdornment?: ReactNode;
  disabled?: boolean;
} & TestAttributes;

export const MenuListItem = forwardRef<HTMLLIElement, MenuListItemProps>(
  ({ 'data-testid': dataTestId, onClick, icon, label, endAdornment, className, disabled }, ref) => {
    return (
      <li
        ref={ref}
        data-testid={dataTestId}
        onClick={disabled ? undefined : onClick}
        className={classNames(
          'flex gap-1.5 w-full items-center  p-9px rounded-md',
          {
            'cursor-not-allowed opacity-50': disabled,
            'cursor-pointer hover:bg-white-snow active:bg-gray-concrete': !disabled,
          },
          className,
        )}
      >
        {icon}
        <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {label}
        </Paragraph>
        <div className='flex items-center ml-auto'>
          {endAdornment ?? <ArrowRightIcon className='h-2.5' color='#808080' />}
        </div>
      </li>
    );
  },
);
