import { apiStatus } from '@va/constants';
import { getFeaturePermissionsStatus, isCompanyRevealFeatureEnabled } from '@va/dashboard/selectors/app';
import { useReportBlockNodeBuilder } from '@va/dashboard/shared/report-block-nodes';
import { useVisitsByCompany } from '@va/dashboard/shared/visitors';
import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { AppliedFilters, FiltersContextProvider, useFiltersContext } from '@va/shared/feature-filters';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { getReportBlockPaginationId, ReportBlock } from '@va/ui/components/report-block';
import { ReportBlockNodeIds } from '@va/ui/components/report-block/nodes';
import { useRefreshContext } from '@va/util/components';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useVisitsByCompanyColumns } from './useVisitsByCompanyColumns';
import { useVisitsByCompanyFilterOptions } from './useVisitsByCompanyFilters';

const id = 'visitor-company-distribution';

export const VisitsByCompanyCard = memo(() => {
  const translate = useTranslate();
  const buildReportBlockNode = useReportBlockNodeBuilder();
  const nodes = useMemo(
    () => ({
      appliedFilters: <AppliedFilters />,
      filterButton: buildReportBlockNode(ReportBlockNodeIds.FILTER_BUTTON),
      pagination: <div id={getReportBlockPaginationId(id)} />,
    }),
    [buildReportBlockNode],
  );

  const visualizationComponents = useMemo(() => ({ [DataViewOption.horizontalBarChart]: VisitsByCompanyTable }), []);
  const filterOptions = useVisitsByCompanyFilterOptions();
  return (
    <FiltersContextProvider reportBlockId={id} allFilterOptions={filterOptions}>
      <ReportBlock
        id={id}
        title={translate('all.companyVisits.card.title')}
        visualization={{ selectedView: DataViewOption.horizontalBarChart }}
        visualizationComponents={visualizationComponents}
        nodes={nodes}
      />
    </FiltersContextProvider>
  );
});

const VisitsByCompanyTable = () => {
  const { pagination, setPagination } = useControlledTableState();
  const { appliedFilterValues } = useFiltersContext();
  const featurePermissionsStatus = useSelector(getFeaturePermissionsStatus);

  const showIp2Company = useSelector(isCompanyRevealFeatureEnabled);

  const shouldFetchData = useMemo(() => {
    return featurePermissionsStatus === apiStatus.SUCCEEDED;
  }, [featurePermissionsStatus]);

  const {
    isLoading,
    data = {
      items: [],
      meta: {
        page: 0,
        pageTotal: 0,
        total: 0,
      },
    },
    mutate,
  } = useVisitsByCompany(
    {
      hideCompanyOrgName: !showIp2Company,
      pageSize: pagination.pageSize,
      page: pagination.pageNumber,
      ...appliedFilterValues,
    },
    shouldFetchData,
  );
  const columns = useVisitsByCompanyColumns();
  const { isManualRefreshing } = useRefreshContext();
  useMutateOnRefresh(mutate);

  return (
    <DataTableV8
      id='visits-by-company'
      isLoading={isLoading || isManualRefreshing}
      data={data.items}
      columns={columns}
      state={{ pagination }}
      rowCount={data.meta.total}
      onPaginationChange={setPagination}
      tableStyle={{ gridTemplateColumns: 'auto 1fr auto' }}
      paginationContainerId={getReportBlockPaginationId(id)}
    />
  );
};
