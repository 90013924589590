import { PencilEditIcon } from '@va/icons';
import { Button } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useVisitorSegmentsContext } from '../VisitorSegmentsContext';
import { RightSideView } from '../types';

const SegmentsActionsCell = ({ isDefault, id }: { isDefault: boolean; id: string }) => {
  const { onActionBtnClick, isSegmentSelected, rightSideView } = useVisitorSegmentsContext();

  const isSegmentInEdit = useMemo(
    () => rightSideView === RightSideView.EDIT && isSegmentSelected(id),
    [id, isSegmentSelected, rightSideView],
  );

  return (
    <Button
      color='tertiary'
      icon={() => <PencilEditIcon color={isSegmentInEdit ? '#FFFFFF' : '#3C3C3C'} />}
      className={isSegmentInEdit ? '!bg-green-pure' : undefined}
      disabled={isDefault}
      onClick={() => {
        if (isDefault) return;
        onActionBtnClick(id);
      }}
    />
  );
};

export default SegmentsActionsCell;
