export enum ReportBlockNodeIds {
  FILTER_BUTTON = 'filter-button',
  COMPARE_TO = 'compare-to',
  DOWNLOAD_BUTTON = 'download-button',
  SEGMENTS_BUTTON = 'segments-button',
  DISPLAY_VISITOR_PINS_SWITCH = 'display-visitor-pins-switch',
  COMMISSION_PAYOUTS = 'commission-payouts',
  PRODUCT_SELECTOR = 'product-selector',
  METRIC_OPTIONS = 'metric-options',
}
