import { useReportBlockNodeBuilder } from '@va/dashboard/shared/report-block-nodes';
import { useDataView } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { AppliedFilters, FiltersContextProvider, TextFilter } from '@va/shared/feature-filters';
import { AlarmingEventTrigger } from '@va/types/events';
import { FilterOperators } from '@va/types/filters';
import { DataViewOption } from '@va/types/report-block';
import { ReportBlockProvider } from '@va/ui/components/report-block';
import { ReportBlockNodeIds } from '@va/ui/components/report-block/nodes';
import SplitPanel from '@va/ui/components/visualization/SplitPanel';
import { HorizontalScroll } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { AlarmingBehaviorEventsContextProvider } from '../../context/ABEContext';
import { useAlarmingBehaviorEventsFilteringOptions } from '../../hooks';
import { AlarmingBehaviorEventsDetailsLineChart } from '../visualization/line-chart/ABEDetailsLineChart';
import { AbesByPageTable } from './AbesByPageTable';

const ABES_BY_PAGE_RB = 'abes_by_page_rb';
export const AbesByPageRb = () => {
  const translate = useTranslate();
  const buildReportBlockNode = useReportBlockNodeBuilder();
  // TODO Update filtering?
  const filterOptions = useAlarmingBehaviorEventsFilteringOptions();
  const { trigger } = useParams<{ trigger: AlarmingEventTrigger }>();
  const initialFilters = useMemo(
    () => [
      new TextFilter({
        id: '1',
        name: 'trigger',
        label: 'trigger',
        operator: FilterOperators.equal,
        values: [trigger],
      }),
    ],
    [trigger],
  );

  const nodes = useMemo(
    () => ({
      filterButton: buildReportBlockNode(ReportBlockNodeIds.FILTER_BUTTON),
      appliedFilters: <AppliedFilters />,
      pagination: <div id={ABES_BY_PAGE_RB} />,
    }),
    [buildReportBlockNode],
  );

  const visualization = useDataView({
    defaultView: DataViewOption.splitTableLineDiagram,
    identifier: ABES_BY_PAGE_RB,
  });

  return (
    <FiltersContextProvider allFilterOptions={filterOptions}>
      <AlarmingBehaviorEventsContextProvider
        paginationId={ABES_BY_PAGE_RB}
        initialFilters={initialFilters}
        group={'page'}
      >
        <ReportBlockProvider
          id={ABES_BY_PAGE_RB}
          title={translate('all.alarmingBehaviorEvents.abesByPageRb.title')}
          titleTooltip={translate('all.alarmingBehaviorEvents.abesByPageRb.titleTooltip')}
          nodes={nodes}
          visualization={visualization}
        >
          <SplitPanel
            left={<AbesByPageTable />}
            right={
              <HorizontalScroll className='h-full'>
                <div className='p-6 h-full min-w-[300px]'>
                  <AlarmingBehaviorEventsDetailsLineChart
                    noDataAvailableKey={`all.eventTracking.alarmingBehaviorEventCategory.noAlarmingBehaviorEventSelected`}
                    titleKey={'all.eventTracking.alarmingBehaviorEventCategory.tooltipTitle'}
                    subtitleKey={'all.eventTracking.alarmingBehaviorEventCategory.tooltipSubtitle'}
                    trigger={trigger}
                  />
                </div>
              </HorizontalScroll>
            }
          />
        </ReportBlockProvider>
      </AlarmingBehaviorEventsContextProvider>
    </FiltersContextProvider>
  );
};
