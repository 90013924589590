import { TEST_IDS } from '@va/constants';
import { CheckMarkWithFilledBackground, FilterIcon, RightArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Filter, NestedFiltersPage, isFilter, isNestedFiltersPage } from '@va/types/filters';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import { Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { getResponsiveTooltipHeight } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { FilterOptions, useFiltersContext } from '../filters-context';
import { FilterActionButtons } from './FilterActionButtons';
import { SubFilterTabHeader } from './SubFilterTabHeader';

export const AvailableFiltersList = ({
  availableFilters,
  onFilterClick,
  closeDropdown,
  onBackClick,
  title,
}: {
  availableFilters: FilterOptions;
  onFilterClick: (filter: Filter | NestedFiltersPage) => void;
  closeDropdown: () => void;
  onBackClick?: () => void;
  title?: string;
}) => {
  const [filterValue, setFilterValue] = useState('');

  const { height } = useWindowDimensions();
  const { isFilterApplied } = useFiltersContext();

  const translate = useTranslate();

  const filteredOptions = useMemo(() => {
    if (!filterValue) return availableFilters;

    const filters: FilterOptions = [];

    const findRec = (options: FilterOptions) => {
      options.forEach((item) => {
        const isNested = isNestedFiltersPage(item);
        const labelMatches = item.label.toLowerCase().includes(filterValue.toLowerCase());

        //If filter not nested and label matches, add filter to the array;
        if (labelMatches) {
          if (
            !isNested ||
            item.filters.every((filter) => !filter.label.toLowerCase().includes(filterValue.toLowerCase()))
          ) {
            filters.push(isNested ? (item as NestedFiltersPage) : (item as Filter));
          }
        }

        //If filter is nested, add parent filter to array if searchValue doesn't match any child filter
        if (isNested) {
          findRec(item.filters);
        }
      });
    };

    findRec(availableFilters);

    return filters;
  }, [availableFilters, filterValue]);

  return (
    <div className='overflow-hidden flex flex-col'>
      {title && onBackClick && <SubFilterTabHeader onBackClick={onBackClick} title={title} />}
      <SearchFilterV2 className='shrink-0' onChange={setFilterValue} shouldClearField={!filterValue} />
      <ul
        style={{ height: getResponsiveTooltipHeight(height) }}
        className='mt-1 px-2 overflow-auto min-h-[100px] max-h-[250px] scrollbar scrollbar-thumb'
      >
        {filteredOptions.map((item, index) => {
          const isApplied = isFilter(item) && isFilterApplied(item.id);
          const hasAppliedNestedFilters =
            isNestedFiltersPage(item) && item.filters.some((filter) => isFilterApplied(filter.id));

          return (
            <li
              data-testid={TEST_IDS.helpers.createListItemId(item.id)}
              key={index}
              onClick={() => {
                onFilterClick(item);
                setFilterValue('');
              }}
              className={classNames(
                'filters-list-item cursor-pointer rounded-12 overflow-hidden flex mb-1.5 last:mb-0 hover:bg-white-snow',
                {
                  'filters-list-item--applied bg-gray-concrete': isApplied || hasAppliedNestedFilters,
                },
              )}
            >
              {(item as Filter).logicalOperator && (
                <div className='filters-list-item__logical-operator hidden bg-gray-mercury-darker items-center w-[34px]'>
                  <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
                    {translate(`all.filters.combinationalOperators.${(item as Filter).logicalOperator}`)}
                  </Paragraph>
                </div>
              )}
              <div className='flex flex-1 items-center gap-3 p-2 w-full'>
                <div className='flex overflow-hidden items-center gap-2'>
                  <FilterIcon className='w-3 h-3' color='#969696' />
                  <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
                    {item.label}
                  </ParagraphWithTooltip>
                </div>
                {isApplied || hasAppliedNestedFilters ? (
                  <CheckMarkWithFilledBackground className='ml-auto' width='16px' height='16px' />
                ) : (
                  <RightArrowIcon className='ml-auto shrink-0 w-4 h-4' color='#969696' />
                )}
              </div>
            </li>
          );
        })}
        {filteredOptions.length === 0 && (
          <Paragraph className='py-3' weight={fontWeights.medium}>
            {translate('filters.noFiltersFound')}
          </Paragraph>
        )}
      </ul>
      <FilterActionButtons onClose={closeDropdown} />
    </div>
  );
};
