import { BackIconRounded } from '@va/icons';
import { Button, fontWeights, ParagraphWithTooltip } from '@va/ui/design-system';

export const SubFilterTabHeader = ({ onBackClick, title }: { onBackClick: () => void; title: string }) => {
  return (
    <div className='mb-2'>
      <div className='flex items-center'>
        <Button className='mr-3 scale-75' color='tertiary' icon={() => <BackIconRounded />} onClick={onBackClick} />

        <ParagraphWithTooltip weight={fontWeights.medium} colorClassName='text-gray-charcoal'>
          {title}
        </ParagraphWithTooltip>
      </div>

      <div className='h-1.5px bg-gradient-to-r from-gray-gallery-darker via-gray-concrete to-white -mx-1'></div>
    </div>
  );
};
