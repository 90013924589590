import { CogIconV2, EmptyCheckmarkIcon, FilledCheckmarkIcon, SegmentIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, MenuListItem } from '@va/ui/design-system';
import { useHistory } from 'react-router';
import { useSegmentsContext } from './SegmentsContext';

export function AppliedSegmentsList() {
  const history = useHistory();
  const { segments, isSegmentSelected, toggleSegmentSelection } = useSegmentsContext();
  const translate = useTranslate();

  return (
    <div className='flex flex-col overflow-hidden'>
      <ul className='overflow-auto scrollbar scrollbar-thumb'>
        {segments?.map((segment) => (
          <MenuListItem
            key={segment.id}
            label={segment.name}
            icon={<SegmentIconV2 />}
            endAdornment={
              isSegmentSelected(segment.id) ? (
                <FilledCheckmarkIcon className='w-18px h-18px' color={'var(--color-primary)'} />
              ) : (
                <EmptyCheckmarkIcon className='w-18px h-18px' />
              )
            }
            onClick={() => {
              toggleSegmentSelection(segment.id);
            }}
          />
        ))}
      </ul>
      <Button
        text={translate('all.segments.editAndManage')}
        icon={(_, color) => <CogIconV2 color={color} />}
        onClick={() => {
          history.push('/settings/visitor-segments');
        }}
        color='tertiary'
        className='w-full mt-[3px] shrink-0 justify-center py-9px'
        textClasses='text-12 leading-18'
        size='small'
      />
    </div>
  );
}
