// Added to the resulting index.html of the WiX app. Not needed on non-WiX apps
const Wix = window.Wix;

/**
 * @memberOf Wix
 * @name Wix.Settings
 */

/**
 * @methodOf Wix.Settings
 * @name Wix.Settings.openBillingPage
 */
export function onUpgrade() {
  console.log('onUpgrade. Calling Wix.Settings.openBillingPage');
  try {
    return Wix.Settings.openBillingPage();
  } catch (e) {
    console.error(e);
  }
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.getProducts
 */
export function getProducts(onSuccess) {
  console.log('getProducts. Calling Wix.Dashboard.getProducts');
  try {
    return Wix.Dashboard.getProducts(onSuccess);
  } catch (e) {
    console.error(e);
  }
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.getEditorUrl
 */
export function getEditorUrl(callback) {
  console.log('getEditorUrl. Calling Wix.Dashboard.getEditorUrl');
  try {
    return Wix.Dashboard.getEditorUrl(callback);
  } catch (e) {
    console.error(e);
  }
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.openBillingPage
 */
export function openBillingPage() {
  console.log('openBillingPage. Calling Wix.Dashboard.openBillingPage');
  try {
    return Wix.Dashboard.openBillingPage();
  } catch (e) {
    console.error(e);
  }
}
