import { ReportBlockProps } from '@va/types/report-block';
import { PropsWithChildren, forwardRef, memo } from 'react';
import { ReportBlockBody, ReportBlockBodyProps } from './ReportBlockBody';
import { ReportBlockDataComponent } from './ReportBlockDataComponent';
import { ReportBlockDisabledOverlay } from './ReportBlockDisabledOverlay';
import { ReportBlockHeader, ReportBlockSubHeader } from './ReportBlockHeader';
import { ReportBlockProvider } from './report-block-ctx';
import { ReportBlockHeaderProvider } from './report-block-header-ctx';

export const ReportBlock = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<ReportBlockProps & ReportBlockBodyProps>>(
    ({ className, border, disabled, ...props }, ref) => {
      return (
        <ReportBlockProvider {...props}>
          <ReportBlockBody className={className} border={border} ref={ref}>
            <ReportBlockHeaderProvider>
              <ReportBlockHeader />
              <ReportBlockSubHeader />
            </ReportBlockHeaderProvider>
            <ReportBlockDataComponent />
            {disabled && <ReportBlockDisabledOverlay message={typeof disabled === 'string' ? disabled : undefined} />}
          </ReportBlockBody>
        </ReportBlockProvider>
      );
    },
  ),
);
