import { FilterConditionsObject } from '@va/types/filter-templates';

// TODO MOVE TYPES TO types lib

/** @deprecated USE IMPORT FROM @va/types lib */
export type VisitorSegment = {
  id: string;
  name: string;
  note?: string | null;
  websiteId: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  conditions: FilterConditionsObject;
};

export type VisitorSegmentsResponse = {
  data: VisitorSegment[] | null;
};

export type Conditions = {
  member: string;
  operator: string;
  values: string[];
};

export type CreateSegmentPayload = {
  createdBy?: string | null;
  name: string;
  note?: string;
  conditions: FilterConditionsObject;
};

export type UpdateSegmentPayload = {
  name: string;
  note?: string;
  conditions: FilterConditionsObject;
};

export type SegmentPayload = {
  createdBy?: string; //required only for create
  name: string;
  note?: string;
  conditions: FilterConditionsObject;
};

export enum RightSideView {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum segmentsSelectOptionsEnum {
  all = 0,
}
