import { createColumnHelper } from '@tanstack/react-table';
import { NotesIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { VisitorSegment } from '@va/types/segments';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { Paragraph, ParagraphWithTooltip, fontWeights } from '@va/ui/design-system';
import { addNumberSeparator } from '@va/util/helpers';
import moment from 'moment';
import { useMemo } from 'react';
import { AuthorCell } from './tableCells/AuthorCell';
import SegmentTypeCell from './tableCells/SegmentTypeCell';
import SegmentsActionsCell from './tableCells/SegmentsActionsCell';
import { Conditions } from './types';

const columnHelper = createColumnHelper<VisitorSegment>();

export const useVisitorSegmentsColumns = () => {
  const translate = useTranslate();
  const { locale } = useLocale();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        meta: {
          cellClassName: '!justify-start !min-w-210',
        },
        header: (context) => <HeaderCellV8 context={context} text={translate('labels.segmentName')} />,
        cell: ({ row }) => {
          const { name, id } = row.original;
          return <SegmentTypeCell name={name} isDefaultSegment={id === 'default'} id={id} />;
        },
        enableSorting: true,
      }),
      columnHelper.accessor('createdBy', {
        header: (context) => <HeaderCellV8 context={context} text={translate('labels.createdBy')} />,
        cell: ({ getValue }) => {
          return <AuthorCell createdBy={getValue()} />;
        },
      }),
      columnHelper.accessor('createdAt', {
        header: (context) => <HeaderCellV8 context={context} text={translate('labels.createdOn')} />,
        cell: ({ getValue }) => {
          const createdAt = moment(getValue()).format('L');
          return <Paragraph className='text-gray-charcoal'>{createdAt}</Paragraph>;
        },
      }),
      columnHelper.accessor('note', {
        header: (context) => <HeaderCellV8 context={context} text={translate('labels.notes')} />,
        cell: ({ getValue }) => {
          const note = getValue();
          if (!note) {
            return <NotesIcon color='#C3C3C3' />;
          }
          return (
            <div className='flex gap-2 items-center max-w-110'>
              <NotesIcon className='shrink-0' color='#3C3C3C' />
              <ParagraphWithTooltip weight={fontWeights.medium} className='text-gray-charcoal'>
                {note}
              </ParagraphWithTooltip>
            </div>
          );
        },
        enableSorting: true,
        sortDescFirst: false,
      }),
      columnHelper.display({
        id: 'segmentFilters',
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('all.visitorSegments.table.headers.filters')} />
        ),
        cell: ({ row }) => {
          const conditionsObject = row.original.conditions;
          const conditionsCount = hasConditions(conditionsObject) ? conditionsObject.and.length : 0;

          return <Paragraph className='text-gray-charcoal'>{addNumberSeparator(conditionsCount, locale)}</Paragraph>;
        },
      }),
      columnHelper.display({
        id: 'segmentActions',
        header: (context) => <HeaderCellV8 context={context} text={translate('tableHeaders.actions')} />,
        cell: ({ row }) => {
          const { id } = row.original;
          return <SegmentsActionsCell isDefault={id === 'default'} id={id} />;
        },
      }),
    ],
    [locale, translate],
  );
  return columns;
};

export function hasConditions(obj: any): obj is { and: Conditions[] } {
  return obj && obj.and !== undefined;
}
