import { MemoExoticComponent, ReactNode } from 'react';
import { TooltipOptions } from '../tooltip';

export type VisualizationOption = NestedVisualizationOption | DataViewOption;

export type NestedVisualizationOption = {
  view: DataViewOption;
  options: DataViewOption[];
};

export function isNestedVisualizationOption(item: VisualizationOption): item is NestedVisualizationOption {
  return (item as NestedVisualizationOption).options !== undefined;
}

export function isSingleVisualizationOption(item: VisualizationOption): item is DataViewOption {
  return typeof item === 'string';
}

export type DataViewProps = {
  selectedView: DataViewOption;
  viewOptions?: VisualizationOption[];
  onViewChange?: (view: DataViewOption) => void;
  size?: 'small' | 'medium';
};

export enum DataViewOption {
  lineDiagram = 'lineDiagram',
  table = 'table',
  barChart = 'barChart',
  stackedLineDiagram = 'stackedLineDiagram',
  steppedLineDiagram = 'steppedLineDiagram',
  radarDiagram = 'radarDiagram',
  horizontalBarChart = 'horizontalBarChart',
  pieChart = 'pieChart',
  kpi = 'kpi',
  map = 'map',
  card = 'card',
  splitTableLineDiagram = 'splitTableLineDiagram',
  splitTablePieDiagram = 'splitTablePieDiagram',
  splitTableRecording = 'splitTableRecording',
}

export type DataViewComponent = MemoExoticComponent<() => JSX.Element> | (() => JSX.Element);

export type ReportBlockNodes = {
  filterButton?: ReportBlockNodeItem;
  segmentsButton?: ReportBlockNodeItem;
  appliedFilters?: React.ReactNode;
  segments?: React.ReactNode;
  bulkActions?: React.ReactNode;
  compareTo?: ReportBlockNodeItem;
  pagination?: React.ReactNode;
  dataViewButton?: React.ReactNode;
  rightSideJSX?: React.ReactNode;
  rightSide?: ReportBlockNodeItem[] | ReportBlockNodeItem;
  downloadButton?: ReportBlockNodeItem;
  productSelector?: ReportBlockNodeItem;
  datePicker?: React.ReactNode;
  subtitle?: React.ReactNode;
};

export type ReportBlockProps = {
  id: string;
  title: string;
  titleTooltip?: string;
  titleTooltipOptions?: TooltipOptions;
  visualization?: DataViewProps;
  visualizationComponents?: Record<string, DataViewComponent>;
  nodes?: ReportBlockNodes;
  showPrevious?: boolean;
  size?: 'small' | 'medium';
  headerClassName?: string;
  disabled?: boolean | string;
};

export type ReportBlockNodeItem = {
  id: string;
  icon: ReactNode;
  label: string;
  endAdornment?: ReactNode;
  headerComponent?: ReactNode;
  menuComponent?: (closeMenu: () => void) => ReactNode;
  hideCloseButton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  tooltipProps?: TooltipOptions;
  visible?: boolean;
};
