import { TEST_IDS } from '@va/constants';
import { ExportIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import {
  Button,
  GrayGradientDivider,
  MenuListItem,
  Paragraph,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';

export type DownloadBtnTooltipContentProps = {
  downloadCSV?: () => void;
  downloadPDF?: () => void;
  downloadPNG?: () => void;
  downloadXLSX?: () => void;
  infoNote?: string;
  title?: string;
};

export const DownloadBtnTooltipContent = ({
  downloadCSV,
  downloadPDF,
  downloadPNG,
  downloadXLSX,
  infoNote,
  title,
}: DownloadBtnTooltipContentProps) => {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  return (
    <div data-testid={TEST_IDS.generic.export.dropdownContainer}>
      {title && (
        <>
          <div className='p-3'>
            <Paragraph colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
              {title}
            </Paragraph>
          </div>
          <GrayGradientDivider />
        </>
      )}
      <ul>
        {downloadCSV && (
          <MenuListItem
            data-testid={TEST_IDS.generic.export.getExportOptionId('csv')}
            icon={<ExportIconV2 />}
            label={translate('all.labels.exportCSV')}
            onClick={downloadCSV}
          />
        )}
        {downloadPDF && (
          <MenuListItem
            data-testid={TEST_IDS.generic.export.getExportOptionId('pdf')}
            icon={<ExportIconV2 />}
            label={translate('all.labels.exportPDF')}
            onClick={downloadPDF}
          />
        )}
        {downloadPNG && (
          <MenuListItem
            data-testid={TEST_IDS.generic.export.getExportOptionId('png')}
            icon={<ExportIconV2 />}
            label={translate('all.labels.exportPNG')}
            onClick={downloadPNG}
          />
        )}
        {downloadXLSX && (
          <MenuListItem
            data-testid={TEST_IDS.generic.export.getExportOptionId('xlsx')}
            icon={<ExportIconV2 />}
            label={translate('all.labels.exportXLSX')}
            onClick={downloadXLSX}
          />
        )}
      </ul>
      {infoNote && (
        <Paragraph
          className='py-1 px-2'
          size={paragraphSizes.tiny}
          colorClassName='text-gray-devil'
          weight={fontWeights.medium}
        >
          {infoNote}
        </Paragraph>
      )}
      <Button
        data-testid={TEST_IDS.generic.buttons.close}
        onClick={() => context.onOpenChange(false)}
        color='tertiary'
        className='w-full mt-[3px]'
        text={translate('button.close')}
        size='small'
      />
    </div>
  );
};
