import { DarkModeIcon } from '@va/icons';
import { useDarkMode } from '@va/util/hooks';

export const DarkModeBtn = () => {
  const { toggle } = useDarkMode();

  return (
    <button onClick={toggle}>
      <DarkModeIcon />
    </button>
  );
};
