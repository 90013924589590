import { CogIcon, EmptyCheckmarkIcon, FilledCheckmarkIcon, LockIconV2, RightArrowIcon, SegmentIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { VisitorSegment } from '@va/types/segments';
import { HorizontalScroll, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { MouseEventHandler, ReactNode, forwardRef, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSegmentsContext } from './SegmentsContext';

type DisplayedVisitorSegment = {
  isSelected: boolean;
  onClick: MouseEventHandler;
} & VisitorSegment;

export function AppliedSegments() {
  const history = useHistory();
  const { segments, isSegmentSelected, toggleSegmentSelection, isExpanded } = useSegmentsContext();
  const translate = useTranslate();
  const editSegmentsWrapperRef = useRef<HTMLDivElement>(null);

  if (!isExpanded) return null;

  return (
    <div className='inline-flex gap-3 pb-1 w-full overflow-hidden'>
      {
        <BaseSegment
          ref={editSegmentsWrapperRef}
          onClick={() => {
            history.push('/settings/visitor-segments');
          }}
          leftIcon={<CogIcon color='#696969' />}
          topContent={
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={fontWeights.semibold}
              colorClassName='text-gray-charcoal'
            >
              {translate('all.segments.editAndManage')}
            </ParagraphWithTooltip>
          }
          rightIcon={<RightArrowIcon color='#969696' />}
          className='sticky left-0 sm:!max-w-150'
        />
      }
      <HorizontalScroll className='overflow-hidden'>
        <div className='flex gap-3'>
          {segments?.map((segment) => (
            <Segment
              key={segment.id}
              {...segment}
              isSelected={isSegmentSelected(segment.id)}
              onClick={() => {
                toggleSegmentSelection(segment.id);
              }}
            />
          ))}
        </div>
      </HorizontalScroll>
    </div>
  );
}

function Segment({ name, note, isSelected, onClick }: DisplayedVisitorSegment) {
  const isDefaultSegment = false;

  return (
    <BaseSegment
      onClick={onClick}
      leftIcon={<SegmentIcon color='#696969' />}
      topContent={
        <ParagraphWithTooltip
          size={paragraphSizes.tiny}
          weight={fontWeights.normal}
          colorClassName='text-gray-charcoal'
        >
          {name}
        </ParagraphWithTooltip>
      }
      bottomContent={
        isDefaultSegment ? (
          <DefaultSegmentMarker />
        ) : (
          <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil'>
            {note}
          </ParagraphWithTooltip>
        )
      }
      rightIcon={isSelected ? <FilledCheckmarkIcon /> : <EmptyCheckmarkIcon />}
    />
  );
}

function DefaultSegmentMarker() {
  const translate = useTranslate();
  return (
    <div className='flex items-center gap-[6px]'>
      <LockIconV2 color='#969696' />
      <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil'>
        {translate('all.segments.defaultSegment')}
      </ParagraphWithTooltip>
    </div>
  );
}

type BaseSegmentProps = {
  leftIcon: ReactNode;
  topContent: ReactNode;
  bottomContent?: ReactNode;
  rightIcon: ReactNode;
  onClick?: MouseEventHandler;
  className?: string;
};

const BaseSegment = forwardRef<HTMLDivElement, BaseSegmentProps>(
  ({ leftIcon, topContent, bottomContent, rightIcon, onClick, className }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'py-3 pl-15px pr-3 flex items-center gap-3 bg-white-snow hover:bg-gray-concrete rounded-15 max-w-[246px] cursor-pointer',
          className,
        )}
        onClick={onClick}
      >
        <div className='flex-shrink-0'>{leftIcon}</div>
        <div className='overflow-hidden'>
          {topContent}
          {bottomContent && bottomContent}
        </div>
        <div className='flex-shrink-0'>{rightIcon}</div>
      </div>
    );
  },
);
