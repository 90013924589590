import { Filter, isNestedFiltersPage } from '@va/types/filters';
import { useCallback, useState } from 'react';
import { FilterOptions, useFiltersContext } from '../filters-context';
import { AvailableFiltersList } from './AvailableFiltersList';
import { IndividualFilter } from './IndividualFilter';

export const AddNewFilterTab = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const { allFilterOptions } = useFiltersContext();

  const [titles, setTitles] = useState<string[]>([]);
  const [filters, setFilters] = useState<FilterOptions[]>([
    allFilterOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
  ]);
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>(undefined);

  const filtersOnCurrentPage = filters[filters.length - 1];
  const titleOnCurrentPage = titles[titles.length - 1];

  const onClose = useCallback(() => {
    closeDropdown();
    setSelectedFilter(undefined);
  }, [closeDropdown]);

  return (
    <>
      {!selectedFilter && (
        <AvailableFiltersList
          closeDropdown={onClose}
          onFilterClick={(item) => {
            if (isNestedFiltersPage(item)) {
              setFilters((prev) => [...prev, item.filters]);
              setTitles((prev) => [...prev, item.label]);
              return;
            }
            setSelectedFilter(item);
          }}
          title={titleOnCurrentPage}
          onBackClick={() => {
            if (filters.length > 1) {
              setFilters((prev) => {
                return prev.slice(0, prev.length - 1);
              });
              setTitles((prev) => {
                return prev.slice(0, prev.length - 1);
              });
            }
          }}
          availableFilters={filtersOnCurrentPage}
        />
      )}
      {selectedFilter && (
        <IndividualFilter
          onBackClick={() => {
            setSelectedFilter(undefined);
          }}
          filter={selectedFilter}
          onClose={onClose}
        />
      )}
    </>
  );
};
