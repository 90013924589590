import { TEST_IDS } from '@va/constants';
import { FilterIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { DropdownArrow } from '@va/util/components';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { AddFilterTooltip } from './add-filter-tooltip/AddFilterTooltip';
import { FILTER_SELECTION_MODAL_CLASS_IDENTIFIER } from './constants';
import { useFiltersContext } from './filters-context';

export const AddFilterButton = ({ className, size = 'large' }: { className?: string; size?: 'small' | 'large' }) => {
  const translate = useTranslate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isMediumDevice } = useWindowDimensions();

  const { appliedFilters, isExpanded, toggleAppliedFiltersVisibility } = useFiltersContext();

  const filtersExist = appliedFilters.length > 0;
  const filterColor = filtersExist ? '#FFFFFF' : '#3C3C3C';

  const handleButtonClick = useCallback(() => {
    if (filtersExist && !isMediumDevice) {
      toggleAppliedFiltersVisibility();
      return;
    }
    setIsDropdownOpen((prev) => !prev);
  }, [filtersExist, isMediumDevice, toggleAppliedFiltersVisibility]);

  return (
    <AddFilterTooltip isOpen={isDropdownOpen} openChange={setIsDropdownOpen}>
      <div className={classNames(className, 'inline-block')}>
        <Button
          color={filtersExist ? 'primary' : 'tertiary'}
          icon={() => (
            <FilterIconV2
              className={classNames({
                'w-15px h-15px': size === 'small',
                'w-18px h-18px sm:w-15px sm:h-15px': size === 'large',
              })}
              color={filterColor}
            />
          )}
          bottomIcon={
            <DropdownArrow
              iconClassName={classNames({
                'w-1.5': size === 'small',
                'w-2.5 sm:w-1.5': size === 'large',
              })}
              color={filterColor}
              open={isDropdownOpen || (filtersExist && isExpanded)}
            />
          }
          data-testid={TEST_IDS.generic.filters.toggleBtn}
          onClick={handleButtonClick}
          className={classNames('sm:w-9 sm:h-9 sm:p-9px sm:rounded-9px', {
            '!bg-green-pure': filtersExist && !(isExpanded || isDropdownOpen),
          })}
          bottomIconWrapperCss='sm:bottom-0.5'
          tooltip={translate('tooltip.label.applyFilters')}
          tooltipProps={{ disabled: isMediumDevice }}
          size={size}
        />
      </div>
    </AddFilterTooltip>
  );
};

export function isClickInsideFilterModal(e: Event) {
  const filterSelectionModal = document.querySelector(`.${FILTER_SELECTION_MODAL_CLASS_IDENTIFIER}`);
  if (filterSelectionModal && filterSelectionModal.contains(e.target as any)) return true;
  return false;
}
