import { LocalStorage } from '@va/util/helpers';
import { useCallback, useEffect } from 'react';

const lsKey = 'visa-theme';
const root = document.querySelector('html') as HTMLElement;

export const useDarkMode = () => {
  const toggle = useCallback(() => {
    if (root.classList.contains('dark')) {
      root.classList.remove('dark');
      LocalStorage.setItem(lsKey, 'light');
    } else {
      root.classList.add('dark');
      LocalStorage.setItem(lsKey, 'dark');
    }
  }, []);

  useEffect(() => {
    const theme = LocalStorage.getItem('visa-theme');

    if (!theme) return;
    if (theme === 'dark') {
      root.classList.add('dark');
    }
  }, []);

  return { toggle };
};
