import { useTranslate } from '@va/localization';
import { DownloadBtn, DownloadBtnTooltipContent } from '@va/ui/components/buttons';
import { useExportRbData } from './useExportRbData';

export const DownloadHeaderNode = () => {
  const translate = useTranslate();
  const { downloadCSV, downloadXLSX } = useExportRbData();

  return (
    <DownloadBtn title={translate('all.exportType.scheduled')} downloadXLSX={downloadXLSX} downloadCSV={downloadCSV} />
  );
};

export const DownloadMenuComponent = () => {
  const { downloadCSV, downloadXLSX } = useExportRbData();

  return <DownloadBtnTooltipContent downloadCSV={downloadCSV} downloadXLSX={downloadXLSX} />;
};
