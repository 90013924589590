import { TestAttributes } from '@va/types/component';
import Switch, { ReactSwitchProps } from 'react-switch';

type ToggleSwitchProps = {
  size?: 'small' | 'large';
} & TestAttributes &
  ReactSwitchProps;

const switchSizes = {
  small: {
    handleDiameter: 15,
    width: 30,
    height: 18,
  },
  large: {
    handleDiameter: 24,
    width: 48,
    height: 30,
  },
};

export const ToggleSwitch = ({ checked = false, size = 'large', ...props }: ToggleSwitchProps) => {
  return (
    <Switch
      offHandleColor='#FFFFFF'
      onHandleColor={'#FFFFFF'}
      offColor='#C3C3C3'
      onColor={window.POSITIVE_COLOR}
      checked={checked}
      checkedIcon={false}
      uncheckedIcon={false}
      {...switchSizes[size]}
      {...props}
    />
  );
};
