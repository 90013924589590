import { MEDIUM_DEVICE_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@va/constants';
import { useEffect, useMemo, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const isMobile = useMemo(() => windowDimensions.width < MOBILE_BREAKPOINT, [windowDimensions.width]);

  const isTablet = useMemo(() => windowDimensions.width < TABLET_BREAKPOINT, [windowDimensions.width]);

  const isMediumDevice = useMemo(() => windowDimensions.width < MEDIUM_DEVICE_BREAKPOINT, [windowDimensions.width]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowDimensions, isMobile, isTablet, isMediumDevice };
}
