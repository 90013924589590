import { EVENTS, TEST_IDS } from '@va/constants';
import { useLocale, useTranslate } from '@va/localization';
import { CommonProps } from '@va/types/component';
import { DataViewProps } from '@va/types/report-block';
import { Button, Heading1, Paragraph, TitleInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator, getPercentageColors } from '@va/util/helpers';
import { useCustomizationContext, useEventsContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DataViewButton } from '../data-visualization/DataViewButton';

export type DashboardCardProps = CommonProps & {
  title: ReactNode;
  isLoading?: boolean;
  placeholdersCount?: number;
  seeMoreButtonLabel?: string;
  onSeeMoreButtonClick?: () => void;
};

export const TitleWithTooltip = ({
  title,
  tooltip,
  className,
}: {
  title: string;
  tooltip?: string;
  className?: string;
}) => {
  const { getCustomValue } = useCustomizationContext();
  const { canEditTitle } = usePermissionsProvider();
  const { localEventBus } = useEventsContext();

  const customTitle = getCustomValue('initialTitle', typeof title === 'string' ? title : String(title));

  if (canEditTitle) {
    return (
      <TitleInput
        name={'title'}
        initialValue={customTitle}
        onSave={(title: string) => {
          localEventBus?.dispatch(EVENTS.editRBName, title);
        }}
      />
    );
  }

  return (
    <TooltipWrapper
      trigger='click'
      disabled={!tooltip}
      content={<div className='min-w-240 md:min-w-320'>{tooltip}</div>}
      interactive
    >
      <Paragraph
        size={paragraphSizes.large}
        weight={fontWeights.medium}
        data-testid={TEST_IDS.generic.title}
        colorClassName='text-gray-charcoal'
        className={classNames(
          'pt-3 mb-3 relative',
          { 'border-b border-dashed border-gray-silver cursor-default': tooltip },
          className,
        )}
      >
        {customTitle}
      </Paragraph>
    </TooltipWrapper>
  );
};

export function DefaultTitle({
  title,
  tooltip,
  rightSideNode,
  className,
  visualization,
}: {
  title: string;
  tooltip?: string;
  rightSideNode?: React.ReactNode;
  className?: string;
  visualization?: DataViewProps;
}) {
  return (
    <div className={classNames('flex items-center gap-2 flex-wrap', className)}>
      <div className='flex items-center'>
        {visualization && <DataViewButton {...visualization} />}
        <TitleWithTooltip title={title} tooltip={tooltip} className='ml-3' />
      </div>
      {rightSideNode && <div className='mr-auto'>{rightSideNode}</div>}
    </div>
  );
}

export function LargeTitle({
  title,
  heading,
  tooltip,
  percentageChange,
  isLoading = false,
  hideSeparator = false,
  reversePercentageColors = false,
  visualization,
  nodes,
}: {
  title: string;
  heading: string;
  tooltip?: string;
  percentageChange: number;
  isLoading?: boolean;
  hideSeparator?: boolean;
  reversePercentageColors?: boolean;
  visualization?: DataViewProps;
  nodes?: {
    filterButton?: React.ReactNode;
    appliedFilters?: React.ReactNode;
  };
}) {
  const { filterButton, appliedFilters } = nodes ?? {};
  const { locale } = useLocale();
  const translate = useTranslate();
  return (
    <>
      <div className='flex flex-col items-center text-center relative'>
        {visualization ? (
          <div className='flex items-center w-full gap-2 justify-between'>
            <div>
              <DataViewButton {...visualization} />
            </div>
            <TitleWithTooltip title={title} tooltip={tooltip} className='sm:pt-0' />
            {!nodes && <div className='h-18px w-18px invisible'></div>}
            {filterButton && filterButton}
          </div>
        ) : (
          <TitleWithTooltip title={title} tooltip={tooltip} />
        )}
        {appliedFilters && appliedFilters}
        <Heading1
          data-testid={TEST_IDS.generic.dashboardCard.heading}
          className={'w-full'}
          weight={fontWeights.semibold}
        >
          {isLoading ? <Skeleton /> : heading}
        </Heading1>
        <Paragraph
          size={paragraphSizes.large}
          weight={fontWeights.medium}
          colorClassName={classNames(getPercentageColors(percentageChange, reversePercentageColors))}
          className='p-3 w-full'
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            <TooltipWrapper content={translate('percentComparedToPrevious')}>
              <span
                data-testid={TEST_IDS.generic.dashboardCard.percentageChange}
                className={classNames(getPercentageColors(percentageChange, reversePercentageColors))}
              >
                {addNumberSeparator(percentageChange, locale, {
                  style: 'percent',
                  signDisplay: 'exceptZero',
                })}
              </span>
            </TooltipWrapper>
          )}
        </Paragraph>
      </div>
      {!hideSeparator && (
        <div className='bg-gradient-to-r from-gray-gallery-darker h-[1.5px] -mx-2 sm-initial:-mx-18 mt-30 mb-18px' />
      )}
    </>
  );
}

/** @deprecated */
export const DashboardCard = forwardRef<HTMLDivElement, PropsWithChildren<DashboardCardProps>>(
  (
    {
      children,
      title,
      seeMoreButtonLabel,
      isLoading,
      placeholdersCount = 6,
      onSeeMoreButtonClick,
      className = 'rounded-30 w-full',
      style,
      ...rest
    },
    ref,
  ) => {
    const { getCustomValue } = useCustomizationContext();

    return (
      <div
        data-testid={rest['data-testid']}
        ref={ref}
        className={classNames('report-block flex flex-col bg-white relative p-2 md:p-18', className)}
        style={style}
      >
        {title}
        {isLoading ? (
          <div className='grow overflow-hidden'>
            <Skeleton count={placeholdersCount} className='h-72 mb-1 rounded-18' />
          </div>
        ) : (
          <>
            {children}
            {getCustomValue('showLinks', seeMoreButtonLabel) && (
              <Button
                data-testid={TEST_IDS.generic.buttons.seeMore}
                color='tertiary'
                className='w-full pt-3 mt-auto shrink-0'
                onClick={onSeeMoreButtonClick}
                text={seeMoreButtonLabel}
              />
            )}
          </>
        )}
      </div>
    );
  },
);
