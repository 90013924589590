import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import { SelectableButtonsGroup, buildSelectableButton } from '@va/ui/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useGlobalFiltersConfig } from '../ctx';
import { useFiltersContext } from '../filters-context';
import { AddNewFilterTab } from './AddNewFilterTab';
import './filters-dropdown.scss';

type Tab = {
  label: string;
  component: ({ closeDropdown }: { closeDropdown: () => void }) => JSX.Element;
  disabled: boolean;
} & TestAttributes;

type AvailableFiltersDropdownProps = { closeDropdown: () => void };

const buildTab = (tab: Tab) => tab;

export const AvailableFiltersDropdown = (props: AvailableFiltersDropdownProps) => {
  return (
    <div
      data-testid={TEST_IDS.generic.filters.dropdownContainer}
      className='p-1.5 space-y-1 bg-white text-gray-charcoal rounded-18px shadow-overlay w-auto md:w-480px'
    >
      <AvailableFiltersDropdownContent {...props} />
    </div>
  );
};

export const AvailableFiltersDropdownContent = ({ closeDropdown }: AvailableFiltersDropdownProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const { templatesEnabled } = useFiltersContext();
  const { TemplatesTab } = useGlobalFiltersConfig();

  const translate = useTranslate();

  const tabs = useMemo<Tab[]>(() => {
    const list = [
      buildTab({
        label: translate('all.filters.addNewFilter'),
        component: AddNewFilterTab,
        disabled: false,
        'data-testid': TEST_IDS.generic.filters.newFilterTabBtn,
      }),
    ];

    if (TemplatesTab) {
      list.push(
        buildTab({
          label: translate('all.filterTemplates.tabName'),
          component: TemplatesTab,
          disabled: !templatesEnabled,
          'data-testid': TEST_IDS.generic.filters.filterTemplatesTabBtn,
        }),
      );
    }

    return list;
  }, [TemplatesTab, templatesEnabled, translate]);

  const ActiveTabComponent = useMemo(() => tabs[activeTab]?.component, [activeTab, tabs]);

  const tabButtonOptions = useMemo(
    () =>
      tabs.map((tab, index) =>
        buildSelectableButton({
          label: tab.label,
          value: index,
          className: '!flex-1',
          disabled: tab.disabled,
          'data-testid': tab['data-testid'],
        }),
      ),
    [tabs],
  );

  useEffect(() => {
    // We need this if a tab is added or removed dynamically
    if (!ActiveTabComponent) {
      setActiveTab((prev) => prev - 1);
    }
  }, [ActiveTabComponent]);

  return (
    <div className='space-y-1 overflow-hidden flex flex-col'>
      {tabs.length > 0 && (
        <SelectableButtonsGroup
          flexDirectionClass='flex-row !gap-0'
          buttons={tabButtonOptions}
          selectedValue={activeTab}
          small={true}
          onChange={(val) => {
            setActiveTab(val as number);
          }}
        />
      )}
      {ActiveTabComponent && <ActiveTabComponent closeDropdown={closeDropdown} />}
    </div>
  );
};
