import { TEST_IDS } from '@va/constants';
import { ExportIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { memo } from 'react';
import { DownloadBtnTooltipContent, DownloadBtnTooltipContentProps } from './DownloadBtnTooltipContent';

export const DownloadBtn = memo((props: DownloadBtnTooltipContentProps) => {
  return (
    <TooltipWrapper
      interactive
      useDefaultStyle={false}
      arrow={false}
      trigger='click'
      tooltipClassNames='bg-white rounded-10px p-1.5 min-w-360 shadow-xl'
      content={<DownloadBtnTooltipContent {...props} />}
    >
      <div>
        <Btn />
      </div>
    </TooltipWrapper>
  );
});

const Btn = () => {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  return (
    <Button
      data-testid={TEST_IDS.generic.export.toggleBtn}
      onClick={() => {}}
      className='h-12 sm:h-9 sm:w-9 sm:p-9px shrink-0 sm:rounded-9px'
      color='tertiary'
      icon={() => <ExportIconV2 className='w-18px sm:w-15px sm:h-15px' color='#404040' />}
      bottomIcon={<DropdownArrow iconClassName='w-2.5 sm:w-1.5' open={context.open} color='#404040' />}
      tooltip={translate('tooltip.label.exportData')}
      bottomIconWrapperCss='sm:bottom-0.5'
    />
  );
};
